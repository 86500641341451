import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        usuario:{
            logged: false
        },
        configuracion: {
            musica: 'Medieval',
            efectos: 'activar',
            idioma: 'es'
          },
          informacion: {

          },
          portada: true
    },
    
    reducers: {
        login: (state, action) =>{
            state.usuario = {...action.payload, logged: true}
        },
        setConfiguracion:  (state, action) =>{
            state.configuracion = {...action.payload}
        },
        setInformacion:  (state, action) =>{
            state.informacion = {...action.payload}
        },
        setPortada: (state, action) => {
            state.portada = false
        },
        exit: (state, action) => {
            state.usuario = {
                logged: false
            }
        }
    }
})
export default userSlice.reducer
export const { login, setConfiguracion, setInformacion, setPortada,exit } = userSlice.actions