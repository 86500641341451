import { Box, Typography } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useEffect, useState } from "react"
import { depositStake } from "../../../services/depositStake"
import { mirarStake } from "../../../services/mirarStake"
import { useDispatch, useSelector } from "react-redux"
import { retiroStake } from "../../../services/retiroStake"
import { getCookie } from "../../../utils/getCookies"
import { rutas } from "../../../rutas/rutas"
import { setInformacion } from "../../../store/slices/usuarios"
import { Alerta } from "../../ux/Alert"
import axios from "axios"
const produccion =[
    {
        item: 'Water', cantidad: 1, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680463586/CITYFARMING/agua_ay1q7p.png'
    },
    {
        item: 'Wood', cantidad: 1, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680463588/CITYFARMING/madera_hvpf5a.png'
    },
    {
        item: 'Carbon', cantidad: 0.25, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680463586/CITYFARMING/carbon_epy7k7.png'
    },
    {
        item: 'Wheat', cantidad: 1, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1681670271/CITYFARMING/assets%20wallet/image_1_prgglk.png'
    },
    {
        item: 'Corn', cantidad: 1, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1681670270/CITYFARMING/assets%20wallet/image_u7r7pw.png'
    },
    {
        item: 'Corn Bag', cantidad: 2, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680727644/CITYFARMING/image_2_wzvgtv.png'
    },
    {
        item: 'Wheat Bag', cantidad: 2, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680727644/CITYFARMING/image_3_imgniw.png'
    },
   
    {
        item: 'Egg', cantidad: 3, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680463586/CITYFARMING/huevo_fiyzsu.png'
    },
    {
        item: 'Milk', cantidad: 3, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680463588/CITYFARMING/leche_ekdu14.png'
    },
   
    
    {
        item: 'Energy', cantidad: 0.5, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680992031/CITYFARMING/assets/rayo_b1cspv.png'
    }, {
        item: 'KOD', cantidad: 0.05, max: 200 ,ratio: 10,url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680992031/CITYFARMING/assets/rayo_b1cspv.png'
    }, {
        item: 'HUESO', cantidad: 0.0005, max: 20000,ratio: 2000 ,url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680992031/CITYFARMING/assets/rayo_b1cspv.png'
    }

]



export const Stake = () => {

    const [cantidad, setCantidad] = useState(0)
    const [punto, setPunto] = useState(0)
    const [item, setItem] = useState("Water")
    const [data, setData] = useState([])
    const [user, setUser] = useState({})
    const [pool, setPool] = useState(0)
    const {informacion  } = useSelector(state=> state.userSlice)
    const dispatch = useDispatch()
    const [error, setError] = useState(false)
    const [open, setOpen] = useState(false)
    const [mensaje, setMensaje] = useState('')
    const handleClose = ()=> setOpen(false)

const handleInfo = ()=>{
    
    const tokenJWT = getCookie('token')
    if(tokenJWT){



      axios.get(`${rutas.getUsuario}`, { headers: {'token': tokenJWT}})
      .then(({data})=> {
    
        dispatch(setInformacion(data))
  
      })
      .catch(err=>{
        console.log(err)
    
      })
      
    }
}
    const handlePunto = ()=>{
        const cnatidadNumber = parseInt(cantidad)
        if(cnatidadNumber > 0){
            const itenMain = produccion.find(i=> i.item === item)
            const total = cnatidadNumber * itenMain?.cantidad
            setPunto(total)
        }
       
 
    }
    const getStake = async()=>{
        const res = await mirarStake()
        let total = 0 
        const sort = res.data.stake.sort((a,b)=>{
            if (a.puntos > b.puntos) {
                return -1;
              }
              if (a.puntos < b.puntos) {
                return 1;
              }
            
              // names must be equal
              return 0;
        })
        setPool(res.data.pool.cantidad)
        setData(sort)
        sort.forEach(element => {
            total += element.puntos
        });
        const usuario = sort.find(i=> i?.id === informacion._id)
        if(usuario){
            const reward = (usuario.puntos / total ) * (res.data.pool.cantidad * 0.02)
            setUser({puntos: usuario?.puntos, reward: reward, cantidad: usuario?.cantidad})
        }else{
            setUser({puntos: 0, reward: 0, cantidad: 0})
        }
       
    }
    const retirar = async()=>{
        try {
           await retiroStake(  )
           getStake()
           handleInfo()
           setError(false)
           setMensaje('SUCCESS')
           setOpen(true)
          

        } catch (error) {
            setError(true)
            setOpen(true)
            setMensaje(error?.response?.data?.error||"error")
        }
       
    }
    const deposito = async()=>{
        try {
            if(cantidad > 0 ){
                const res = await depositStake(item, cantidad)
                getStake()
                handleInfo()
                setError(false)
                setMensaje('SUCCESS')
                setOpen(true)
            }
          
        } catch (error) {
            setError(true)
            setOpen(true)
            setMensaje(error?.response?.data?.error||"error")
        }
    }
    useEffect(()=>{
        handlePunto()
    }, [cantidad, item])
    useEffect(()=>{
        getStake()
    },[])

    const todo = [...informacion.inventario, {item: "KOD", cantidad: informacion.kod}, , {item: "HUESO", cantidad: informacion.hueso}]
    return (
    <Box sx={{width: "100%", height:"100%"}}>
        <h2 >Earn SWAP.HIVE</h2>
        <Grid2 sx={{width: "100%", height:"95%"}} container spacing={2} > 
        <Grid2 sx={{overflow: "auto", height: "100%"}} className="rpgui-container framed-golden" xs={12} md={6}> 
        {data.map(i=> <Box className="rpgui-container framed-golden"  key={i.nombre}>
            <Typography>Name: {i?.nombre}</Typography>
            <Typography>Points: {i?.puntos}</Typography>
        </Box>)}</Grid2>
        <Grid2  sx={{overflow: "auto", height: "100%"}} className="rpgui-container framed-golden" xs={12} md={6}>
                   
        <select value={item} onChange={(e)=> setItem(e.target.value)} style={{width: "100%", marginBottom: "5px"}} class="rpgui-dropdown ">
{produccion.map(i=> 	<option key={i.item} value={i.item}>{i.item}</option>)}

	
</select>
<input value={cantidad} onChange={(e)=>e.target.value > 0 ? setCantidad(e.target.value) : setCantidad('') } style={{marginBottom: "5px"}} type="number" min={0}  placeholder="Quantity"></input>
<p sx={{color: "green"}}>{cantidad > 0 && punto }</p>
<button style={{marginBottom: "5px"}} onClick={deposito} className="rpgui-button"> <p>Deposit</p></button>
{ user?.cantidad > 0.1 && <button onClick={retirar} className="rpgui-button"> <p>Withdraw</p></button>}
<Typography sx={{color: "red !important"}}>Every 1st of each month 75% of your current points will be burned.</Typography>

<p >Inventory: {todo.find(i=>i?.item ===item)?.cantidad }</p>
<p >Point: {user?.puntos}</p>
<p >Tokens earned: {user?.cantidad} </p>
<p >Weekly reward: {user?.reward?.toFixed(4)} SWAP.HIVE </p>
<p>Total pool available: {pool}</p>
<p>To be distributed on a weekly basis 2%. </p>
<p style={{color:"red"}}>The tokens deposited in the stake cannot be withdrawn later, they are transformed into points.</p>


             </Grid2>


        </Grid2>
       <Alerta mensaje={mensaje} open={open} handleClose={handleClose} error={error} />

    </Box>
  )
}


