import axios from "axios"
import { rutas } from "../rutas/rutas"
import { getCookie } from "../utils/getCookies"

export const cancelarMarket = async(id)=>{
   
        const tokenAUTH = getCookie('token')
         return axios({
            url: rutas.cancelarOrden,
            data: {id},
            method: 'post',
            headers:{
                token: tokenAUTH
            }
        })

    
       
 
}
  