import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import axios from 'axios'
import { rutas } from '../../rutas/rutas'
import { useState } from 'react'
import { useDispatch } from "react-redux";
import { setInformacion } from '../../store/slices/usuarios'
import { signin } from '../../keychain/login'
export const Registrar = ({setPagina,login}) => {
  const [usuario, setUsuario ] = useState('')
  const [password, setPassword ] = useState('')
  const urlCompleta = window.location.href;

  // Obtener el objeto URL
  const url = new URL(urlCompleta);
  
  // Obtener parámetros individuales
  const referido = url.searchParams.get('refer');
 
  const dispatch = useDispatch()

  const registrarEmail = ()=>{

    axios.post(rutas.registarEmail, {password: password, user: usuario, referido:referido}).then(({data})=>{
      if(data?.resultado){
        const token = data?.token
        document.cookie = `token=${token}; max-age=${3600 * 12}; path=/; samesite=strict;secure;`
        
        dispatch(setInformacion(data?.resultado))
        setPagina(3)
      }
    
    }).catch(e=> {
     
      alert('Error password or email')
    })
  }
  const handleLogin = (infoUser) =>{
  

    dispatch(setInformacion(infoUser?.resultado))
    setPagina(3)
  }
  const handleRegister = ()=>{
    if(password === ''){
      signin(usuario, handleLogin, null,false, referido)
    }else{
      if(password?.length>= 4 && password?.length < 25){
        registrarEmail()
      }else{
        alert('Error password')
      }
    }
  }
  return (
    <Box sx={{width:'100%', height: '70vh'}}>
        <Typography sx={{fontSize: '2rem !important', textAlign: 'center'}}>{login ? 'Signup': 'Login'}</Typography>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width:'100%', height: '100%', flexDirection: 'column'}}>
     
        <p sx={{fontSize: '1rem !important', textAlign: 'center'}}>You can register with email and a password, and play, but you will not be able to withdraw funds until you associate it with a hive wallet.</p>
      <input onChange={(e)=> setUsuario(e?.target?.value)} type="text" placeholder='Email or username hive'></input>
      <input onChange={(e)=> setPassword(e?.target?.value)} type="password" placeholder='Password '></input>
    {login ? <p>Password only if you register with email</p> : <p>Password only if you login with e-mail</p>}
          <Button onClick={handleRegister} class="rpgui-button"  ><p>{login ? 'Signup': 'Login'}</p></Button>
         {/* <Button class="rpgui-button"  ><p>collect</p></Button>
         <Button class="rpgui-button"  ><p>    plant seeds</p></Button> */}
     
        </Box>
       
    </Box>
  )
}
