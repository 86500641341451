import { Box, Button, Dialog, DialogTitle, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { v4 } from 'uuid'
import { Tablet } from './Tablet'
import { marketVenta } from '../../services/marketVenta'
import { miraToken } from '../../services/miraToken';
import { useDispatch, useSelector } from 'react-redux';
import { comprarToken } from '../../services/comprarToken';
import { setInformacion } from '../../store/slices/usuarios';
import { PacksMarket } from './PacksMarket';
import { Alerta } from '../ux/Alert';
import { cancelarMarket } from '../../services/cancelarMarket';
import { tokenHistoyry } from '../../services/tokenHistoyry'
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: 0,
  };





const tokens = [ {
    item: 'Water', cantidad: 350, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680463586/CITYFARMING/agua_ay1q7p.png'
},
{
    item: 'Wood', cantidad: 500, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680463588/CITYFARMING/madera_hvpf5a.png'
},
{
    item: 'Carbon', cantidad: 500, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680463586/CITYFARMING/carbon_epy7k7.png'
},
{
    item: 'Wheat', cantidad: 450, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1681670271/CITYFARMING/assets%20wallet/image_1_prgglk.png'
},
{
    item: 'Corn', cantidad: 500, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1681670270/CITYFARMING/assets%20wallet/image_u7r7pw.png'
},
{
    item: 'Corn Bag', cantidad: 300, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680727644/CITYFARMING/image_2_wzvgtv.png'
},
{
    item: 'Wheat Bag', cantidad: 30, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680727644/CITYFARMING/image_3_imgniw.png'
},

{
    item: 'Egg', cantidad: 0, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680463586/CITYFARMING/huevo_fiyzsu.png'
},
{
    item: 'Milk', cantidad: 0, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680463588/CITYFARMING/leche_ekdu14.png'
},
{
    item: 'Energy', cantidad: 150, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680992031/CITYFARMING/assets/rayo_b1cspv.png'
}
]

// {
//     item: 'Wheat seed', cantidad: 140, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680464412/CITYFARMING/sobre_trigo1_sev8rn.png'
// }
// ,
// {
//     item: 'Corn seed', cantidad: 180, url: 'https://res.cloudinary.com/devuvyuij/image/upload/v1680464412/CITYFARMING/sobre_maiz1_xoopl8.png'
// },
export const Market = ({handleClose4}) => {
    const {informacion } = useSelector(state=> state.userSlice)
    const dispatch = useDispatch()
    const [open3, setOpen3] = useState(false);
    const [pagina, setPagina] = useState(1);
    const [error, setError] = useState(false)
    const [ venta, setVenta ] = useState(false)
    const [ hallowen, setHallowen ] = useState(false)
    const [ cantidad, setCantidad ] = useState('')
    const [ cantidadCompra, setCantidadCompra ] = useState('')
    const [ precio, setPrecio ] = useState('')
    const [ token, setToken ] = useState('Corn')
    const [ selector, setSelector ] = useState({})
    const [ criptomoneda, setCriptomoneda ] = useState('CF')
    const [ columnVenta, setColumnVemta ] = useState([])
    const [ columnCompra, setColumCompra ] = useState([])
    const [ rowCompra, setRowCompra ] = useState([])
    const [ rowVenta, setRowVenta ] = useState([])
    const [ historialCompra, setHistorialCompra ] = useState([])
  
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [mensaje, setMensaje] = useState('')
    const handleClose = ()=> setOpen(false)
    const handleClose2 = ()=> setOpen2(false)
    const handleBuy = async()=>{
        try {
            console.log(!selector?._id , cantidadCompra <= 0)
            if(!selector?._id || cantidadCompra <= 0)return
            const res = await  comprarToken(token,precio,criptomoneda,venta, cantidadCompra, selector?._id)
            verToken()
            setError(false)
            setMensaje('SUCCESS')
            setOpen(true)
        } catch (error) {
            setError(true)
            setOpen(true)
      
            const msg = error?.response?.data?.error || "ERROR"
            setMensaje(msg)
        }
 
    }

    const handleClose3 = () => {
        setOpen3(false);
    }
    const verToken = async()=>{
try {
    const respuesta = await miraToken(token, criptomoneda)
    const { compra, venta, informacion} = respuesta?.data
 
    dispatch(setInformacion(informacion))
    const pruebaCompra =[ {
        id: "bid",
        label: compra[0]?.token
    },{
        id: 'cantidad', 
        label: compra[0]?.item
    }
    
    ,{
        id: 'precio', 
        label: 'BID'
    }]
    setColumnVemta([ {
        id: "bid",
        label: venta[0]?.token
    },{
        id: 'cantidad', 
        label: venta[0]?.item
    }
    
    ,{
        id: 'precio', 
        label: 'BID'
    }])
    setColumCompra(pruebaCompra)

setRowCompra(compra.map(i=> ({bid: i?.precio * i?.cantidad, cantidad: i?.cantidad,precio: i?.precio,...i})).reverse())
setRowVenta(venta.map(i=> ({bid: i?.precio * i?.cantidad, cantidad: i?.cantidad,precio: i?.precio,...i})))

    // {
    //     venta: 'KOD',
    //     token: 'Corn',
    //     precio: Math.floor( Math.random() *100),
    //     cantidad:  Math.floor( Math.random() *100),
    //     bid: 0
    // }
} catch (error) {
    console.log(error)
}
    }
    const addVenta = async ()=>{
        try {
            const res = await marketVenta(token,precio,criptomoneda,venta,cantidad, hallowen)
            verToken()
            setError(false)
            setMensaje('SUCCESS')
            setOpen(true)
        } catch (error) {
            setError(true)
            setOpen(true)
      
            const msg = error?.response?.data?.error || "ERROR"
            setMensaje(msg)

          
        }

    } 

const handleCancelar = async()=>{
try {
    const response =  await cancelarMarket(selector?._id)
    verToken()
    dispatch(setInformacion(response?.data))
    setError(false)
    setMensaje('SUCCESS')
    setOpen(true)
    handleClose2()
} catch (error) {
    setError(true)
    setOpen(true)

    const msg = error?.response?.data?.error || "ERROR"
    setMensaje(msg)

  
}

}
const verHistoyrial = async ()=>{
 
  const history =  await tokenHistoyry(token,criptomoneda)
  setHistorialCompra(history.data)
}
useEffect(()=>{
    verToken()
    verHistoyrial()
},[token,criptomoneda])
useEffect(()=>{
    setCantidadCompra(selector?.cantidad)
}, [selector])
const history = [{id: "10101", cantidad: 10, precio: "5", token: "KOD"},{id: "10101", cantidad: 10, precio: "5", token: "KOD"} ,{id: "10101", cantidad: 10, precio: "5", token: "KOD"} ,{id: "10101", cantidad: 10, precio: "5", token: "KOD"} ,{id: "10101", cantidad: 10, precio: "5", token: "KOD"}  ]
  return (
    <Box sx={{ width: '100%', height:'100%', overflow: 'auto'}}>
            <Box onClick={()=>handleClose4()} sx={{position: 'abolute',fontSize: "18px", right: 0, top: 0, zIndex: 100000000001, color: 'gray'}}>X</Box>

        <Box className="rpgui-container framed-golden" sx={{width: '100%', height: '15%'}}>
        <Button sx={{p:"20px !important"}} onClick={()=>setPagina(0)} className="rpgui-button"  ><p>PACKS</p></Button>
        <Button  sx={{p:"20px !important"}}  onClick={()=>setPagina(1)}  className="rpgui-button"  ><p>TOKENS</p></Button>
        </Box>
       {pagina === 1 ? <Box sx={{ display: 'flex', width: '100%', height:'100%', justifyContent: 'space-between', flexDirection: {xs:'column', md: 'row'}}}>
            <Box sx={{height: '100%'}} className="rpgui-container framed-golden-2-market">
                <Typography>Buy Orders</Typography>
                <Box sx={{width: '100%',height: '100%'}}>
                   <Tablet columns={columnCompra} row={rowCompra} setSelector={setSelector} />             
                </Box>
            </Box>
            <Box  sx={{ height: '90%'}}  className="rpgui-container framed-golden-2-market">
                <Typography>Order</Typography>
                <Box sx={{display: 'block'}}>
                <Box sx={{display: 'flex'}}>
                    {/* <Typography>Operations</Typography> */}
                    {(selector?.nombre === informacion?.nombre || selector?.nombre === informacion?.correo)
         
         ?
         <Button class="rpgui-button-m " onClick={()=> setOpen2(true)} ><p>Cancel</p></Button> :
         
         <Box>
                 <Box  sx={{filter: 'grayscale(100%) !important'}}>
   
   { selector?.venta   && <Button class="rpgui-button-m " onClick={handleBuy} ><p>Buy</p></Button>}

      </Box>
      <Box  sx={{filter: 'grayscale(100%) !important'}}>
   { selector?.venta === false &&   <Button class="rpgui-button-m "  onClick={handleBuy}  ><p>Sell</p></Button>}

      </Box>
         </Box>
            }
               

  

     
                    </Box>
                    <Button onClick={()=>{
            setOpen3(true)
            setVenta(false)
            setHallowen(false)

         }}  class="rpgui-button-m " ><Typography> Place a buy order</Typography></Button>
         <Button onClick={()=>{
            setOpen3(true)
            setVenta(true)
            setHallowen(false)
         }} class="rpgui-button " ><Typography >place a sale order</Typography></Button>
          {/* <Button onClick={()=>{
            setOpen3(true)
            setVenta(true)
            setHallowen(true)
         }} class="rpgui-button golden" type="button" ><p >Event hallowen </p></Button> */}
                          
                    <Box sx={{height: "100%"}}>
                    <Typography>Token</Typography>
                    <select value={criptomoneda} onChange={(e)=> setCriptomoneda(e.target.value)} class="rpgui-dropdown">
	<option value="CF">CF</option>
	

</select>
<select value={token} onChange={(e)=> setToken(e.target.value)} class="rpgui-dropdown">
    {tokens.map(i=> <option key={i?.item} value={i?.item}>{i?.item}</option>)}
	


</select>
                    </Box>
       
          
                </Box>
                <input onChange={(e)=>e.target.value > 0 ? setCantidadCompra(e.target.value) : setCantidadCompra('') } value={cantidadCompra} placeholder='Quantity' type='number'></input>

            
                <Box>
                    <Typography>
                    PRICE:{selector?.precio}
                    </Typography>
           <Typography>
           Total quantity: {selector?.cantidad}
           </Typography>
           <Typography>
         {`${criptomoneda} Available`}  : {informacion?.cf}
           </Typography>
           <Typography>
         {`${token} Available`}  : { informacion.inventario.find(i=> i.item === token)?.cantidad}
           </Typography>
        <Typography>
            Total: {selector?.precio  * cantidadCompra ? selector?.precio  * cantidadCompra : 0}
        </Typography>
        <Box sx={{height: "180px", overflow: "auto"}}>
        <h1>History</h1>
        {historialCompra.map(i=><Box sx={{fontSize: "10px", borderBottom: "1px solid gray",mb: 1, p: 1 }} key={v4()}>
         {`Buyer: ${i.comprador} - Seller: ${i.vendedor} - Quantity: ${i.cantidad} - Price: ${i.precio}`  }
        </Box>)}
        </Box>
          </Box>
            </Box>
            <Box  sx={{width: '31%', height: '70%'}}  class="rpgui-container framed-golden-2-market">
                <Typography>Sell Orders</Typography>
                <Box sx={{width: '100%',height: '100%'}}>
                   <Tablet columns={columnVenta} row={rowVenta} setSelector={setSelector} />             
                </Box>
            </Box>
         
        </Box> : <PacksMarket />}

        <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{color:"#fff", display: "grid",placeItems: "center",fontFamily:"Minecraft",letterSpacing: "1.5px", fontSize: "1.2rem"}}  className="rpgui-container framed-golden" >
        <p>
          If you want to cancel the order, you will lose the amount paid for the creation."
      
        </p>
        {selector?.hallowen && <p>If you cancel this order, you will lose 50% of the tokens of the order created.</p>}
        <Box>
        <button  onClick={()=>{
           handleCancelar()
         }}  class="rpgui-button" ><Typography sx={{color: "#fff"}}> I agree</Typography></button>
           <button  onClick={()=>{
           handleClose2()
         }}  class="rpgui-button" ><Typography sx={{color: "#fff"}}> Cancel</Typography></button>
        </Box>

        </Box>
   
      </Dialog>
        <Modal
   open={open3}
   onClose={handleClose3}
   aria-labelledby="modal-modal-title"
   aria-describedby="modal-modal-description"
   sx={{ position: 'absolute'}}
 >
   <Box className="bordes"  sx={{...style, position: 'absolute', maxWidth: "400px"}}>
     <Box className="rpgui-container framed-golden" >
        <Box  className="rpgui-container ">
        <Typography sx={{fontFamily:"Minecraft", color: "#222"}}>Price</Typography>
         <input onChange={(e)=>e.target.value > 0 ? setPrecio(e.target.value) : setPrecio('') } value={precio} placeholder='Price' type='number'></input>
        </Box>
         <Box>
         <Typography  sx={{fontFamily:"Minecraft", color: "#222"}}>Quantity</Typography>
         <input onChange={(e)=>e.target.value > 0 ?  setCantidad(e.target.value) : setCantidad('')}  value={cantidad} placeholder='Quantity' type='number'></input>

         </Box>
         {hallowen && <Typography sx={{fontFamily:"Minecraft", color: "#222"}} >How does it work?</Typography> }
         {hallowen && <Typography sx={{fontFamily:"Minecraft", color: "#222"}} >To create this order you need to sell 10 or more  token water, and you receive 2 points in the stake, you can create several different orders.</Typography> }
         {
            !venta ?        <Button onClick={()=>{
                addVenta()
            }}  class="rpgui-button-m " ><Typography>Place an order</Typography></Button>
            :
            <Button onClick={()=>{
                addVenta()
            }} class="rpgui-button " ><Typography >place a sales order</Typography></Button>
         }
  

     </Box>
  
   </Box>

 
 </Modal>
 <Alerta mensaje={mensaje} open={open} handleClose={handleClose} error={error} />

    </Box>
  )
}
