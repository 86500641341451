export const tierras = [
    {
        id: 1,
        nombre: 'maiz',
        nivel: [
            {
                Wood: 25,
                Energy: 25,
                Carbon: 20,
                Egg: 5,
                Milk: 5,
                time: 24
            },
            {
                Wood: 50,
                Energy: 50,
                Carbon: 320,
                Egg: 15,
                Milk: 15,
                time: 50
            },
            {
                Wood: 100,
                Energy: 100,
                Carbon: 400,
                Egg: 30,
                Milk: 30,
                hueso: 20000,
                time: 100
            },
            {
                Wood: 'Max',
                Energy: 'Max',
                Carbon: 'Max',
                Egg: 'Max',
                Milk: 'Max',
                time: 'Max'
            }
        ]
    },
    {
        id: 2,
        nombre: 'maiz2',
        nivel: [
            {
                Wood: 25,
                Energy: 25,
                Carbon: 20,
                Egg: 5,
                Milk: 5,
                time: 24
            },
            {
                Wood: 50,
                Energy: 50,
                Carbon: 320,
                Egg: 15,
                Milk: 15,
                time: 50
            },
            {
                Wood: 100,
                Energy: 100,
                Carbon: 400,
                Egg: 30,
                Milk: 30,
                hueso: 20000,
                time: 100
            },
            {
                Wood: 'Max',
                Energy: 'Max',
                Carbon: 'Max',
                Egg: 'Max',
                Milk: 'Max',
                time: 'Max'
            }
        ]
    },
    {
        id: 3,
        nombre: 'trigo',
        nivel: [
            {
                Wood: 25,
                Energy: 25,
                Carbon: 20,
                Egg: 5,
                Milk: 5,
                time: 24
            },
            {
                Wood: 50,
                Energy: 50,
                Carbon: 320,
                Egg: 15,
                Milk: 15,
                time: 50
            },
            {
                Wood: 100,
                Energy: 100,
                Carbon: 400,
                Egg: 30,
                Milk: 30,
                kod: 500,
                time: 120
            }    ,
            {
                Wood: 'Max',
                Energy: 'Max',
                Carbon: 'Max',
                Egg: 'Max',
                Milk: 'Max',
                time: 'Max'
            }
            
        ]
    },
    {
        id: 4,
        nombre: 'trigo2',
        nivel: [
            {
                Wood: 25,
                Energy: 25,
                Carbon: 20,
                Egg: 5,
                Milk: 5,
                time: 24
            },
            {
                Wood: 50,
                Energy: 50,
                Carbon: 320,
                Egg: 15,
                Milk: 15,
                time: 50
            },
            {
                Wood: 100,
                Energy: 100,
                Carbon: 400,
                Egg: 30,
                Milk: 30,
                kod: 500,
                time: 120
            }    ,
            {
                Wood: 'Max',
                Energy: 'Max',
                Carbon: 'Max',
                Egg: 'Max',
                Milk: 'Max',
                time: 'Max'
            }
        ]
    },
    {
        id: 5,
        nombre: 'arbol',
        nivel: [
            {
                Energy: 30,
                Water: 30,
                Egg: 5,
                Milk: 5,
                time: 40
            },
            {
                Energy: 60,
                Water: 60,
                Egg: 10,
                Milk: 10,
                time: 80
            },
            {
                Energy: 120,
                Water: 120,
                Egg: 20,
                Milk: 20,
                hueso: 20000,
                time: 160
            },
            {
                Energy: "Max",
                Water:  "Max",
                Egg:  "Max",
                Milk:  "Max",
                time:  "Max"
            }
        ]
    },
    
    {
        id: 6,
        nombre: 'arbol2',
        nivel: [
            {
                Energy: 30,
                Water: 30,
                Egg: 5,
                Milk: 5,
                time: 40
            },
            {
                Energy: 60,
                Water: 60,
                Egg: 10,
                Milk: 10,
                time: 80
            },
            {
                Energy: 120,
                Water: 120,
                Egg: 20,
                Milk: 20,
                hueso: 20000,
                time: 160
            },
            {
                Energy: "Max",
                Water:  "Max",
                Egg:  "Max",
                Milk:  "Max",
                time:  "Max"
            }
        ]
    },
    {
        id: 7,
        nombre: 'arbol3',
        nivel: [
            {
                Energy: 30,
                Water: 30,
                Egg: 5,
                Milk: 5,
                time: 40
            },
            {
                Energy: 60,
                Water: 60,
                Egg: 10,
                Milk: 10,
                time: 80
            },
            {
                Energy: 120,
                Water: 120,
                Egg: 20,
                Milk: 20,
                hueso: 20000,
                time: 160
            },
            {
                Energy: "Max",
                Water:  "Max",
                Egg:  "Max",
                Milk:  "Max",
                time:  "Max"
            }
        ]
    },{
        id: 8,
        nombre: 'agua',
        nivel: [
            {
                Energy: 30,
                Carbon: 120,
                Egg: 10,
                Milk: 10,
                time: 40
            },
            {
                Energy: 60,
                Carbon: 240,
                Egg: 20,
                Milk: 20,
                time: 80
            },
            {
                Energy: 120,
                Carbon: 480,
                Wood: 60,
                Egg: 40,
                Milk: 40,
                kod: 500,
                time: 160
            },
            {
                Energy: "Max",
                Water:  "Max",
                Egg:  "Max",
                Milk:  "Max",
                time:  "Max"
            }
        ]
    },
    {
        id: 9,
        nombre: 'energia',
        nivel: [
            {
                Water: 20,
                Carbon: 140,
                Egg: 12,
                Milk: 10,
                time: 40
            },
            {
                Water: 50,
                Carbon: 280,
                Egg: 30,
                Milk: 30,
                time: 80
            },
            {
                Water: 100,
                Carbon: 600,
                Egg: 60,
                Milk: 60,
                kod: 500,
                time: 160,
            },
            {
                Energy: "Max",
                Carbon:  "Max",
                Egg:  "Max",
                Milk:  "Max",
                time:  "Max"
            }
        ]
    }
    ,
    {
        id: 10,
        nombre: 'granja1',
        nivel: [
            {
                Water: 25,
                Carbon: 140,
                "Corn Bag": 10,
                Wood: 10,
                time: 50
            },
            {
                Water: 50,
                Carbon: 280,
                "Corn Bag": 20,
                Wood: 50,
                time: 90
            },
            {
                Water: 100,
                Carbon: 560,
                "Corn Bag": 40,
                Wood: 100,
                hueso: 20000,
                time: 180
            },
            {
                Water: "Max",
                Carbon: "Max",
                "Corn Bag": "Max",
                Wood: "Max",
                time: "Max"
            }
        ]
    },
    {
        id: 11,
        nombre: 'granja2',
        nivel: [
            {
                Water: 25,
                Carbon: 140,
                "Wheat Bag": 10,
                Wood: 10,
                time: 50
            },
            {
                Water: 50,
                Carbon: 280,
                "Wheat Bag": 20,
                Wood: 50,
                time: 90
            },
            {
                Water: 100,
                Carbon: 560,
                "Wheat Bag": 40,
                Wood: 100,
                kod: 500,
                time: 180
            },
            {
                Water: "Max",
                Carbon: "Max",
                "Wheat Bag": "Max",
                Wood: "Max",
                time: "Max"
            }
        ]
    }
    
    
    
]