import axios from "axios"
import { getCookie } from "../utils/getCookies"
import { rutas } from "../rutas/rutas"

export const molinoServices = async(method = 'get', body, url = true)=>{
   
    const token2 = getCookie('token')
     return axios({
        url:  url ? `${ method === 'get' ? rutas.verMolino : rutas.procesarMolino}` : rutas.completarMolino ,
        method: method,
        headers:{
            token: token2
        },
        data: body
     
    })

   

}
