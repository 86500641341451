import axios from "axios"
import { getCookie } from "../utils/getCookies"
import { rutas } from "../rutas/rutas"

export const mirarStake = async(token, criptomoneda)=>{
   
    const token2 = getCookie('token')
     return axios({
        url: `${rutas.mirarStake}`  ,
        method: 'get',
        headers:{
            token: token2
        },
     
    })

   

}
