import {
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { rutas } from "../../rutas/rutas";
import { getCookie } from "../../utils/getCookies";
import { setInformacion } from "../../store/slices/usuarios";
let disabled = false;
export const Objetos = ({ handleClose }) => {
  const { informacion } = useSelector((state) => state.userSlice);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch()
  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedItem(null);
  };
  const use = async (item) => {
    try {
      disabled = true;
      const token2 = getCookie("token");
      const { data } = await axios({
        url: rutas.usarObjeto,
        method: "post",
        headers: {
          token: token2,
        },
        data: {
          item,
        },
      });
    
    
  
      axios.get(`${rutas.getUsuario}`, { headers: {'token': token2}})
      .then(({data})=> {
    
        dispatch(setInformacion(data))
   
      })
      .catch(err=>{
        alert("An unexpected error occurred")
        console.log(err)
        
      })
      
  
     
    
      disabled = false;
 
      handleOpen(data);
    } catch (error) {

      alert("An unexpected error occurred")
    }
  };
  return (
    <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
      <Box
        onClick={handleClose}
        sx={{
          position: "abolute",
          right: 0,
          top: 0,
          zIndex: 10000000000001,
          color: "gray",
        }}
      >
        X
      </Box>

      <Grid2 sx={{ mt: 1, p: 1 }} container spacing={4}>

        
        {informacion.objetos.map((i) => {
          return (
            <Grid2
              flexDirection={"column"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              className="rpgui-container framed-golden"
              xs={6}
              md={3}
              key={i?.item}
            >
              <CardMedia
                className="rpgui-cursor-grab-open"
                component="img"
                image={i?.url}
                sx={{ width: "70%", objectFit: "cover" }}
              />
              <Typography>
                {i?.item} - {i?.cantidad}
              </Typography>

              <Button
                disabled={disabled}
                onClick={() => use(i?.item)}
                class="rpgui-button"
              >
                Use
              </Button>
            </Grid2>
          );
        })}
      </Grid2>

      <Dialog open={open} onClose={handleCloseModal}>
        <DialogContent sx={{alignItems: "center",justifyContent: "center", display: "flex", flexDirection: "column"}} className="rpgui-container framed-golden">
          <DialogTitle>Item won</DialogTitle>
          {selectedItem && (
            <>
              <CardMedia
                className="rpgui-cursor-grab-open"
                component="img"
                image={selectedItem?.url}
                sx={{ width: "50%", objectFit: "cover" }}
              />
              <Typography sx={{ mt: 2 }}>{selectedItem?.premio}</Typography>

              <Button
                sx={{ mt: 2 }}
                variant="contained"
                onClick={() => handleCloseModal()}
                class="rpgui-button"
              >
                Accept
              </Button>
            </>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
