import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { completarConstruccion } from '../../services/completarConstruccion'
import { setInformacion } from '../../store/slices/usuarios'
import { Box, Button, CardMedia, Typography } from '@mui/material'
import dayjs  from 'dayjs'
import { Alerta } from '../ux/Alert'
import { Contador } from '../comun/Contador'

export const Construccion = ({selector}) => {
    const [error, setError] = useState(false)
    const [open, setOpen] = useState(false)
    const [mensaje, setMensaje] = useState('')
    const dispatch = useDispatch()
    const handleClose = ()=> setOpen(false)
    const completarEstructura = async()=>{
        try {
          const { data } = await completarConstruccion(selector?.id)
          dispatch(setInformacion(data))
          setError(false)
          setMensaje('SUCCESS')
          setOpen(true)
        } catch (error) {
          setError(true)
          setOpen(true)
          console.log(error)
          setMensaje(error?.response?.data?.error)
        }
      
        }

  return (
 selector?.construccion === true? <Box sx={{display: 'flex',flexDirection: 'column',placeItems: 'center', position:'absolute', width: '100%', height: '100%', zIndex: 1000, background: 'rgba(0,0,0,0.8)' }}>
    
    <CardMedia
                    className="rpgui-cursor-grab-open"
              component="img"
              image={'https://res.cloudinary.com/devuvyuij/image/upload/v1682621900/CITYFARMING/various%20assets/image_4_m38wwb.png'}
              sx={{ width: "40%", objectFit: "cover", m: '0 auto' }}
            />
           <Typography  variant='p'  sx={{color: 'white !important',fontSize: '1rem !important', textAlign: 'start', mt: 1}}>Ends in:{ selector?.fechaConstruccion !== "" && <Contador segundos={ selector?.fechaConstruccion} /> }</Typography>
          {dayjs().utc().diff(selector?.fechaConstruccion, 'seconds') > 0 && <Button class="rpgui-button golden" onClick={completarEstructura}  ><p>Completed</p></Button>}

           <Alerta mensaje={mensaje} open={open} handleClose={handleClose} error={error} />
    </Box> : null
  )
}
