import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from "uuid";

export const Tablet = ({columns,row, setSelector}) => {
  const {informacion } = useSelector(state=> state.userSlice)

  return (
    <TableContainer  className="scroll scroll-small"  sx={{ height: "100%", background: "#0E1314 " }}>
    <Table  stickyHeader aria-label="sticky table">
      <TableHead  color="white">
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
    
              style={{ background: "rgba(255,255,255,0.3)", color: "white" }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {row.map((row) => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={uuidv4()}
              onClick={()=>setSelector(row)}
            >
              {columns.map((column, index) => {
                const value = row[column.id];
             
                return (
                  <TableCell
                    style={{
                      background: (row?.nombre === informacion?.nombre || row?.nombre === informacion?.correo) ? "rgba(255,255,255,0.5)" :  row?.hallowen ? "orange" :  "rgba(0,0,255,0.1)",
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={()=>{
               
                    }}
                    key={uuidv4()}
                    align={column.align}
                  >
                    {value}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
  )
}
