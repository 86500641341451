import axios from "axios"
import { rutas } from "../rutas/rutas"
import { getCookie } from "../utils/getCookies"

export const subirNivelServices = async(id)=>{
  
        const token = getCookie('token')

         return axios({
            url: rutas.edificaciones,
            data: {id},
            method: 'post',
            headers:{
                token: token
            }
        })
    
       
 
}
  