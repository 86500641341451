import { Box, Typography } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { signin, transfer } from "../../keychain/login"
import { cambiarPass } from "../../services/cambiarPass"
import { Stake } from "./profile/Stake"
import { getCookie } from "../../utils/getCookies"
import { rutas } from "../../rutas/rutas"
import axios from "axios"
import { setInformacion } from "../../store/slices/usuarios"
import Referido from "./profile/Referido"
import CambioCf from "./profile/CambioCf"

export const Profile = ({handleClose}) => {
    const {informacion  } = useSelector(state=> state.userSlice)
    const [user, setUser ] = useState("")
    const [pass, setPass ] = useState("")
    const [ token, settoken ] = useState("KOD")
    const [ cantidad, setCantidad ] = useState("")
    const [open, setOpen] = useState(false);
    const [open3, setOpen3] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose2 = () => setOpen(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);
    const dispatch = useDispatch()
//signin
const handlePass = async()=>{
  try {
    if(pass?.length<=3 || pass?.length >24) return
    await cambiarPass()
    alert("succes")
  } catch (error) {
    alert("error")
  }
}

const handleDeposit = ()=>{
 
if(parseFloat(cantidad) > 0 && !informacion.nombre?.includes("@null")) {

  transfer(cantidad, `Deposit ${token}`, token,informacion?.nombre)
}

}
const handleRetiro = async()=>{
 try {
// alert("For security reasons, withdrawals are disabled for the first few days after its launch, until we check that there are no problems and bugs that can be exploited by players.")

//   return 
  const tokenAUTH = getCookie('token')
  await axios({
     url: rutas.retiroReal,
     data: {token},
     method: 'post',
     headers:{
         token: tokenAUTH
     }
 })
 axios.get(`${rutas.getUsuario}`, { headers: {'token': tokenAUTH}})
 .then(({data})=> {

   dispatch(setInformacion(data))

 })
 alert("SUCCES")
 } catch (error) {
  alert(error?.response?.data?.error)

 }
}
const handleAsociar = async ()=>{
  if( informacion?.nombre?.includes('@null') ){
    signin(user,()=>{},null,true)
  }
  if(informacion?.nombre === 'undefined'){

  }
  
}
  return (
    <> 

    <Grid2 sx={{width: '100%', height: '100%', overflow: 'auto', position: "relative"}} className="rpgui-container" container spacing={2}>

      <Grid2  className="rpgui-container framed-golden" xs={12} md={7}>
      <Box onClick={handleClose} sx={{position: 'abolute', right: 0, top: 0, zIndex: 10000000000001, color: 'gray'}}>X</Box>

        <Typography>User HIVE: {informacion?.nombre}</Typography>
        <Typography>Email : {informacion?.correo}</Typography>
      {  informacion?.nombre?.includes('@')   &&  <input onChange={(e)=>setUser(e.target.value)} placeholder="User"></input>}
        <input  onChange={(e)=>setPass(e.target.value)} placeholder="Password"></input>
        <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
        <button  onClick={handleOpen3} className="rpgui-button"   ><p>convert CF </p></button>
        <button  onClick={handleOpen} className="rpgui-button"   ><p>referrals </p></button>
        <button  onClick={handlePass} className="rpgui-button"   ><p>Password </p></button>
{   informacion?.nombre?.includes('@')   &&  <button onClick={handleAsociar} style={{padding: '40px !important'}} className="rpgui-button"   ><p>associate a HIVE </p></button>
}    
 

{/* {   informacion?.nombre === 'undefined'   &&  <button className="rpgui-button"   ><p>associate a mail </p></button>
} */}
        </Box>

 </Grid2>   
        <Grid2 sx={{}} className="rpgui-container framed-golden" xs={12} md={5}>
        <Box>
<Typography>Open tickets: {informacion?.tiketAbiertos || 0}</Typography>
<Typography>Hive token earned :   {informacion?.hiveGanado || 0}</Typography>
 


  
</Box>
        <Typography>HIVE: {informacion?.hive}</Typography>
        <Typography>KOD: {informacion?.kod}</Typography>
        <Typography>HUESO: {informacion?.hueso}</Typography>
        <Typography>CF: {informacion?.cf || 0}</Typography>
        <input value={cantidad} onChange={(e)=>setCantidad(e.target.value)} placeholder="Quantity"></input>

        <select value={token} typeof="number" onChange={(e)=>settoken(e.target.value)} style={{width: '100%'}} class="rpgui-dropdown ">
	<option value="KOD">KOD</option>
	<option value="HUESO">HUESO</option>
	<option value="SWAP.HIVE">SWAP.HIVE</option>


</select>
        <button onClick={handleDeposit} className="rpgui-button"   ><p>deposit </p></button>
        <button className="rpgui-button" onClick={handleRetiro}  ><p>withdraw all</p></button>

        </Grid2>       
        <Grid2 sx={{height: '100%', overflow: "auto", width:"100%"}}  className="rpgui-container framed-golden"  xs={12}>
          <Stake />

   
        </Grid2>  
        </Grid2>
<Referido open={open} handleClose={handleClose2} />
<CambioCf  open={open3} handleClose={handleClose3} />
    </>

  )
}
