import React, { useEffect, useState } from "react";
import { Box, Button, CardMedia, Typography } from "@mui/material";

import Modal from "@mui/material/Modal";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import fondo from "../image/aaa.png";
import casa from "../image/casa.png";
import trigoLand from "../image/tieraTrigo.png";
import maizLand from "../image/pasto.png";
import molino from "../image/Land_Electrica.png";
import agua from "../image/Land_pozo.png";
import granja from "../image/Land_vacas.png";
import granja2 from "../image/land_gallinas.png";
import { Agua } from "./Modales/Agua";
import { TierraMaiz } from "./Modales/TierraMaiz";
import { Trigo } from "./Modales/Trigo";
import { useSelector } from "react-redux";
import { Arbol } from "./Modales/Arbol";
import { Wallet } from "./Modales/Wallet";
import { Energia } from "./Modales/Energia";
import { Vaca } from "./Modales/Vaca";
import { Gallinero } from "./Modales/Gallinero";
import { Market } from "./market/Market";
import { Molino } from "./molino/Molino";
import { Alerta } from "./ux/Alert";
import { Profile } from "./Modales/Profile";
import { Ecosistema } from "./Modales/Ecosistema";
import { Torneos } from "./eventos/Torneos";
import { Objetos } from "./Modales/Ojbetos";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: "80%" },
  height: "99%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};
const arbol =
  "https://res.cloudinary.com/devuvyuij/image/upload/v1682520600/CITYFARMING/ARBOL/image_3_amwxov.png";

export const Prueba = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [pagina, setPagina] = useState(0);
  const [selector, setSelector] = useState({});
  const { informacion } = useSelector((state) => state.userSlice);
  const idValidos = [10, 11, 5, 9, 12, 15];
  const handleOpen = (id, item) => {
    if (!item?.habilitado && !idValidos.includes(id)) {
      setOpen2(true);
      return;
    }
    setPagina(id);
    setSelector(item);
    setOpen(true);
  };
  useEffect(() => {
    if (pagina > 0) {
      const tierras = { ...informacion?.tierras };
      let tierra = {};

      for (const key in tierras) {
        const element = tierras[key];
        if (element?.id === selector?.id) {
          tierra = tierras[key];
        }
      }
      setSelector(tierra);
    }
  }, [informacion]);
  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);
  useEffect(() => {
    setOpen(true);
    setPagina(13);
  }, []);
  return (
    <Box
      className={`rpgui-container framed `}
      sx={{
        maxWidth: 1000,
        width: { xs: "100%", sm: "70%" },
        height: { xs: "60%", sm: "75%" },
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "hidden",
        flexDirection: "column",
        position: "fixed",
      }}
    >
      <Box
        onClick={() => {
          document.cookie =
            "token" + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          window.location.reload();
        }}
        sx={{
          position: "absolute",
          cursor: "pointer",
          zIndex: 10001,
          right: 0,
          background: "rgba(0,0,0,0.9)",
          color: "gray",
          fontSize: "1.2rem",
        }}
      >
        X
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundImage: `url(${fondo})`,
          backgroundSize: "cover",
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gridTemplateRows: "repeat(3, 1fr)",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          
          <CardMedia
            onClick={() => handleOpen(10)}
            className="rpgui-cursor-grab-open"
            component="img"
            image={
              "https://res.cloudinary.com/devuvyuij/image/upload/v1696354434/CITYFARMING/LAND%20GIF%20PRODUCCION/casa_kkd6oo.gif"
            }
            sx={{ width: { xs: "125%", md: "100%" }, objectFit: "cover" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
          }}
        >
                    <Typography sx={{ color: "white", position: "absolute", right: 30, top:30}}>{ "LVL-" + informacion?.tierras?.arbol.nivel}</Typography>

          <CardMedia
            onClick={() => handleOpen(4, informacion?.tierras?.arbol)}
            className="rpgui-cursor-grab-open"
            component="img"
            image={
              informacion?.tierras?.arbol?.tiempo === ""
                ? arbol
                : dayjs()
                    .utc()
                    .diff(informacion?.tierras?.arbol?.tiempo, "second") > 0
                ? "https://res.cloudinary.com/devuvyuij/image/upload/v1696354434/CITYFARMING/LAND%20GIF%20PRODUCCION/image_3_amwxov-_1_rdub2h.gif"
                : "https://res.cloudinary.com/devuvyuij/image/upload/v1695848233/CITYFARMING/LAND%20GIF/image_3_amwxov_xfjxws.gif"
            }
            sx={{
              width: { xs: "100%", md: "65%" },
              objectFit: "cover",
              "&:hover": {
                filter: informacion?.tierras?.arbol?.habilitado
                  ? ""
                  : "grayscale(100%)",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
          }}
        >
          <Typography sx={{ color: "white", position: "absolute", right: 30, top:30}}>{ "LVL-" + informacion?.tierras?.arbol2.nivel}</Typography>
          <CardMedia
            onClick={() => handleOpen(4, informacion?.tierras?.arbol2)}
            className="rpgui-cursor-grab-open"
            component="img"
            image={
              informacion?.tierras?.arbol2?.tiempo === ""
                ? arbol
                : dayjs()
                .utc()
                .diff(informacion?.tierras?.arbol2?.tiempo, "second") > 0
            ? "https://res.cloudinary.com/devuvyuij/image/upload/v1696354434/CITYFARMING/LAND%20GIF%20PRODUCCION/image_3_amwxov-_1_rdub2h.gif"
                : "https://res.cloudinary.com/devuvyuij/image/upload/v1695848233/CITYFARMING/LAND%20GIF/image_3_amwxov_xfjxws.gif"
            }
            sx={{
              width: { xs: "100%", md: "65%" },
              objectFit: "cover",
              "&:hover": {
                filter: informacion?.tierras?.arbol2?.habilitado
                  ? ""
                  : "grayscale(100%)",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
          }}
        >
 <Typography sx={{ color: "white", position: "absolute", right: 30, top:30}}>{ "LVL-" + informacion?.tierras?.arbol3.nivel}</Typography>

          <CardMedia
            onClick={() => handleOpen(4, informacion?.tierras?.arbol3)}
            className="rpgui-cursor-grab-open"
            component="img"
            image={
              informacion?.tierras?.arbol3?.tiempo === ""
                ? arbol
                : dayjs()
                .utc()
                .diff(informacion?.tierras?.arbol3?.tiempo, "second") > 0
            ? "https://res.cloudinary.com/devuvyuij/image/upload/v1696354434/CITYFARMING/LAND%20GIF%20PRODUCCION/image_3_amwxov-_1_rdub2h.gif"
                : "https://res.cloudinary.com/devuvyuij/image/upload/v1695848233/CITYFARMING/LAND%20GIF/image_3_amwxov_xfjxws.gif"
            }
            sx={{
              width: { xs: "100%", md: "65%" },
              objectFit: "cover",
              "&:hover": {
                filter: informacion?.tierras?.arbol3?.habilitado
                  ? ""
                  : "grayscale(100%)",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            "&:hover": {
              filter: informacion?.tierras?.trigo?.habilitado
                ? ""
                : "grayscale(100%)",
            },
          }}
        >
 <Typography sx={{ color: "white", position: "absolute", right: 30, top:30}}>{ "LVL-" + informacion?.tierras?.trigo.nivel}</Typography>

          <CardMedia
            onClick={() => handleOpen(3, informacion?.tierras?.trigo)}
            className="rpgui-cursor-grab-open"
            component="img"
            image={
              informacion?.tierras?.trigo?.tiempo === ""
                ? trigoLand
                : dayjs()
                .utc()
                .diff(informacion?.tierras?.trigo?.tiempo, "second") > 0
            ? "https://res.cloudinary.com/devuvyuij/image/upload/v1696354486/CITYFARMING/LAND%20GIF%20PRODUCCION/tieraTrigo_dzc5iz.gif"
                : "https://res.cloudinary.com/devuvyuij/image/upload/v1695848234/CITYFARMING/LAND%20GIF/tieraTrigo_vamgie.gif"
            }
            sx={{ width: { xs: "100%", md: "70%" }, objectFit: "cover" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              filter: informacion?.tierras?.trigo2?.habilitado
                ? ""
                : "grayscale(100%)",
            },
            position: "relative"
          }}
        >
 <Typography sx={{ color: "white", position: "absolute", right: 30, top:30}}>{ "LVL-" + informacion?.tierras?.trigo2.nivel}</Typography>

          <CardMedia
            onClick={() => handleOpen(3, informacion?.tierras?.trigo2)}
            className="rpgui-cursor-grab-open"
            component="img"
            image={
              informacion?.tierras?.trigo2?.tiempo === ""
                ? trigoLand
                : dayjs()
                .utc()
                .diff(informacion?.tierras?.trigo2?.tiempo, "second") > 0
            ? "https://res.cloudinary.com/devuvyuij/image/upload/v1696354486/CITYFARMING/LAND%20GIF%20PRODUCCION/tieraTrigo_dzc5iz.gif"
                : "https://res.cloudinary.com/devuvyuij/image/upload/v1695848234/CITYFARMING/LAND%20GIF/tieraTrigo_vamgie.gif"
            }
            sx={{ width: { xs: "100%", md: "70%" }, objectFit: "cover" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            "&:hover": {
              filter: informacion?.tierras?.maiz?.habilitado
                ? ""
                : "grayscale(100%)",
            },
          }}
        >

<Typography sx={{ color: "white", position: "absolute", right: 30, top:30}}>{ "LVL-" + informacion?.tierras?.maiz.nivel}</Typography>

          <CardMedia
            onClick={() => handleOpen(2, informacion?.tierras?.maiz)}
            className="rpgui-cursor-grab-open"
            component="img"
            image={
              informacion?.tierras?.maiz?.tiempo === ""
                ? maizLand
                : dayjs()
                .utc()
                .diff(informacion?.tierras?.maiz?.tiempo, "second") > 0
            ? "https://res.cloudinary.com/devuvyuij/image/upload/v1696354466/CITYFARMING/LAND%20GIF%20PRODUCCION/pasto_ztxtxi.gif"
                : "https://res.cloudinary.com/devuvyuij/image/upload/v1695848232/CITYFARMING/LAND%20GIF/pasto_gwkr4m.gif"
            }
            sx={{ width: { xs: "100%", md: "70%" }, objectFit: "cover" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              filter: informacion?.tierras?.maiz2?.habilitado
                ? ""
                : "grayscale(100%)",
            },
            position: "relative"
          }}
        >
          <Typography sx={{ color: "white", position: "absolute", right: 30, top:30}}>{ "LVL-" + informacion?.tierras?.maiz2.nivel}</Typography>

          <CardMedia
            onClick={() => handleOpen(2, informacion?.tierras?.maiz2)}
            className="rpgui-cursor-grab-open"
            component="img"
            image={
              informacion?.tierras?.maiz2?.tiempo === ""
                ? maizLand
                : dayjs()
                .utc()
                .diff(informacion?.tierras?.maiz2?.tiempo, "second") > 0
            ? "https://res.cloudinary.com/devuvyuij/image/upload/v1696354466/CITYFARMING/LAND%20GIF%20PRODUCCION/pasto_ztxtxi.gif"
                : "https://res.cloudinary.com/devuvyuij/image/upload/v1695848232/CITYFARMING/LAND%20GIF/pasto_gwkr4m.gif"
            }
            sx={{ width: { xs: "100%", md: "70%" }, objectFit: "cover" }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
          }}
        >
          <Typography sx={{ color: "white", position: "absolute", right: 30, top:30}}>{ "LVL-" + informacion?.tierras?.energia.nivel}</Typography>

          <CardMedia
            onClick={() => handleOpen(6, informacion?.tierras?.energia)}
            className="rpgui-cursor-grab-open"
            component="img"
            image={
              informacion?.tierras?.energia?.tiempo === ""
                ? molino
                : "https://res.cloudinary.com/devuvyuij/image/upload/v1695848233/CITYFARMING/LAND%20GIF/Land_Electrica_rn9jba.gif"
            }
            sx={{
              width: { xs: "100%", md: "70%" },
              objectFit: "cover",
              "&:hover": {
                filter: informacion?.tierras?.energia?.habilitado
                  ? ""
                  : "grayscale(100%)",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
          }}
        >
          <Typography sx={{ color: "white", position: "absolute", right: 30, top:30}}>{ "LVL-" + informacion?.tierras?.granja2.nivel}</Typography>

          <CardMedia
            onClick={() => handleOpen(7, informacion?.tierras?.granja2)}
            className="rpgui-cursor-grab-open"
            component="img"
            image={
              informacion?.tierras?.granja2?.tiempo === ""
                ? granja :
                dayjs()
                .utc()
                .diff(informacion?.tierras?.granja2?.tiempo, "second") > 0
            ? "https://res.cloudinary.com/devuvyuij/image/upload/v1696354439/CITYFARMING/LAND%20GIF%20PRODUCCION/Land_vacas_rbqjdw.gif"
            
                : "https://res.cloudinary.com/devuvyuij/image/upload/v1695848232/CITYFARMING/LAND%20GIF/Land_vacas_wwluno.gif"
            }
            sx={{
              width: { xs: "100%", md: "70%" },
              objectFit: "cover",
              "&:hover": {
                filter: informacion?.tierras?.granja2?.habilitado
                  ? ""
                  : "grayscale(100%)",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
          }}
        >
          <Typography sx={{ color: "white", position: "absolute", right: 30, top:30}}>{ "LVL-" + informacion?.tierras?.granja1.nivel}</Typography>

          <CardMedia
            onClick={() => handleOpen(8, informacion?.tierras?.granja1)}
            className="rpgui-cursor-grab-open"
            component="img"
            image={
              informacion?.tierras?.granja1?.tiempo === ""
                ? granja2
                :
                dayjs()
                .utc()
                .diff(informacion?.tierras?.granja1?.tiempo, "second") > 0
            ? "https://res.cloudinary.com/devuvyuij/image/upload/v1696354434/CITYFARMING/LAND%20GIF%20PRODUCCION/land_gallinas_vfbfg8.gif"
                : "https://res.cloudinary.com/devuvyuij/image/upload/v1695848233/CITYFARMING/LAND%20GIF/land_gallinas_tzafkm.gif"
            }
            sx={{
              width: { xs: "100%", md: "70%" },
              objectFit: "cover",
              "&:hover": {
                filter: informacion?.tierras?.granja1?.habilitado
                  ? ""
                  : "grayscale(100%)",
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
          }}
        >
          <Typography sx={{ color: "white", position: "absolute", right: 30, top:30}}>{ "LVL-" + informacion?.tierras?.agua.nivel}</Typography>

          <CardMedia
            onClick={() => handleOpen(1, informacion?.tierras?.agua)}
            className="rpgui-cursor-grab-open"
            component="img"
            image={
              informacion?.tierras?.agua?.tiempo === ""
                ? agua
                : "https://res.cloudinary.com/devuvyuij/image/upload/v1695848233/CITYFARMING/LAND%20GIF/Land_pozo_f3yh2a.gif"
            }
            sx={{
              width: { xs: "100%", md: "70%" },
              objectFit: "cover",
              "&:hover": {
                filter: informacion?.tierras?.agua?.habilitado
                  ? ""
                  : "grayscale(100%)",
              },
            }}
          />
        </Box>
      </Box>
      <Box
        className={`rpgui-container framed`}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bottom: 10,
          position: "fixed",
          flexDirection: { md: "row" },
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
        >
          <Button onClick={() => handleOpen(12)} class="rpgui-button">
            <p>ECOSYSTEM</p>
          </Button>
          <Button onClick={() => handleOpen(9)} class="rpgui-button">
            <p>Market</p>
          </Button>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
        >
          {" "}
          <Button onClick={() => handleOpen(11)} class="rpgui-button">
            <p>Profile</p>
          </Button>
          <Button onClick={() => handleOpen(5)} class="rpgui-button">
            <p>Inventory</p>
          </Button>
          <Button onClick={() => handleOpen(15)} class="rpgui-button">
            <p>Objects</p>
          </Button>
        </Box>
      </Box>
      {/* <CardMedia
      onClick={()=> {
        setOpen(true);
        setPagina(14)}}
              className="rpgui-cursor-grab-open"
             component="img"
             image={"https://res.cloudinary.com/lavino29/image/upload/v1697494752/KOD/image_5_nn7tyy.png"}
             sx={{ width: {xs: 70, md: "130px"}, objectFit: "cover", m: '0 auto', position: "fixed", right: 20, top:{ xs: 5, md: 50 } }}
           /> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="rpgui-content"
      >
        <Box className={`rpgui-container framed `} sx={style}>
          {pagina === 1 && (
            <Agua handleClose={handleClose} selector={selector} />
          )}
          {pagina === 2 && (
            <TierraMaiz selector={selector} handleClose={handleClose} />
          )}
          {pagina === 3 && (
            <Trigo selector={selector} handleClose={handleClose} />
          )}
          {pagina === 4 && (
            <Arbol handleClose={handleClose} selector={selector} />
          )}
          {pagina === 5 && <Wallet handleClose={handleClose} />}
          {pagina === 6 && (
            <Energia handleClose={handleClose} selector={selector} />
          )}
          {pagina === 7 && (
            <Vaca handleClose={handleClose} selector={selector} />
          )}
          {pagina === 8 && (
            <Gallinero handleClose={handleClose} selector={selector} />
          )}
          {pagina === 9 && <Market handleClose4={handleClose} />}
          {pagina === 10 && <Molino handleClose4={handleClose} />}
          {pagina === 11 && <Profile handleClose={handleClose} />}
          {pagina === 12 && <Ecosistema handleClose={handleClose} />}
          {pagina === 13 && (
            <Box>
              <Box
                onClick={handleClose}
                sx={{
                  position: "abolute",
                  right: 0,
                  top: 0,
                  zIndex: 10000000000001,
                  color: "white",
                }}
              >
                X
              </Box>
              
              <CardMedia
                className="rpgui-cursor-grab-open"
                component="img"
                image={
                  "https://res.cloudinary.com/devuvyuij/image/upload/v1715871481/CITYFARMING/nuevo%20mayo%20%202024/feliz_cumplea%C3%B1os_2_esbtmz.gif"
                }
                sx={{ width: "75%", objectFit: "cover", m: "0 auto" }}
              />
           
     
            </Box>
          )}
                {pagina === 14 && (
            <Box sx={{width: "100%", height: "100%"}}>
              <Box
                onClick={handleClose}
                sx={{
                  position: "abolute",
                  right: 0,
                  top: 0,
                  zIndex: 10000000000001,
                  color: "white",
                }}
              >
                X
              </Box>
         <Torneos />

            </Box>
          )}
           {pagina === 15 && <Objetos handleClose={handleClose} />}
        </Box>
      </Modal>
      <Alerta
        mensaje={"You need to buy the land"}
        open={open2}
        handleClose={handleClose2}
        error={true}
      />
    </Box>
  );
};
