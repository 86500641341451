import { Box, Button, CardMedia, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import agua from '../../image/rayo.png'
import axios from "axios"
import Land_Electrica from '../../image/Land_Electrica.png'
import { setInformacion } from '../../store/slices/usuarios'
import { Alerta } from '../ux/Alert'
import dayjs  from 'dayjs'
import utc from 'dayjs/plugin/utc.js'
import { recolectarSevice } from '../../services/recolectarSevice'
import { rutas } from '../../rutas/rutas'
import { getCookie } from '../../utils/getCookies'
import { tierras } from '../niveles/tierras'
import { Construccion } from './Construccion'
import { SubirNivelButton } from './botones/SubirNivelButton'
import { Contador } from '../comun/Contador'
dayjs.extend(utc)
export const Energia = ({selector, handleClose: handleClose2}) => {
  const [semillas, setSemillas] = useState(1)
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const {informacion  } = useSelector(state=> state.userSlice)

  const dispatch = useDispatch()

  const handleClose = ()=> setOpen(false)
  const handleChange = (e)=>{
    const cantidad = parseInt(e.target.value)
    // if(cantidad > selector?.nivel)return
  
    setSemillas(cantidad)
  }
  const recolectarSemilla = async()=>{
try {
  const { data } = await recolectarSevice(selector?.id)
  dispatch(setInformacion(data))
  setError(false)
  setMensaje('SUCCESS')
  setOpen(true)
} catch ({response}) {
  setError(true)
  setOpen(true)
  setMensaje(response.data.error)
}
  }
  const activarAgua = async()=>{
    try {
      const token = getCookie('token')
      const {data} = await axios({
        url: rutas.activarAgua,
        data: {id: 9},
        method: 'post',
        headers:{
            token: token
        }
    })

      dispatch(setInformacion(data))
      setError(false)
      setMensaje('SUCCESS')
      setOpen(true)
    } catch (error) {
      setError(true)
      setOpen(true)

      setMensaje('ERROR')
    }
    
   
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative !important', overflow: 'auto !important', width: '100%', height: '100%'}}>
      <Construccion selector={selector} />
      <Box onClick={handleClose2} sx={{position: 'abolute', right: 0, top: 0, zIndex: 10001, color: 'gray'}}>X</Box>
      <Box  class="rpgui-container framed-golden" sx={{position: 'relative !important'}}>
      <Typography variant='p'  sx={{color: 'white !important',fontSize: '0.7rem !important', textAlign: 'start', mt: 1}}>Inventory:  </Typography>
        <Typography variant='p'  sx={{color: 'white !important',fontSize: '0.7rem !important', textAlign: 'start', mt: 1}}>Carbon: {informacion?.inventario?.find(i=> i?.item === 'Carbon')?.cantidad} </Typography>
        <Typography variant='p'  sx={{color: 'white !important',fontSize: '0.7rem !important', textAlign: 'start', mt: 1}}>Energy: {informacion?.inventario.find(i=> i?.item === 'Energy')?.cantidad} </Typography>

      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%', flexDirection: {xs: 'column', md: 'row'}, overflow: 'auto !important', width: '100%', height: '100%'}}>

  
    <Box class="rpgui-container framed-golden" sx={{position: 'relative !important',width: '50%', display: 'flex',  alignItems: 'center',justifyContent: 'center', flexDirection: 'column',height: '70vh' }}>
    <CardMedia
                     className="rpgui-cursor-grab-open"
               component="img"
               image={Land_Electrica}
               sx={{ width: "50%", objectFit: "cover", m: '0 auto' }}
             />
                            <Box sx={{width: '100%', height: '60%', display: 'flex',  alignItems: 'center',justifyContent: 'center' }}>
           <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
           <Typography variant='p' sx={{fontSize: '1rem !important',color: 'greenyellow !important'}}>+LVL UP <div style={{backgroundSize: '100%', width: '30px', height: '30px'}} class="rpgui-icon exclamation"></div> </Typography>
     {
      Object.entries(tierras.find(i=> i.id === selector.id).nivel[selector?.nivel - 1]).map(i=><Typography key={i[1]} variant='p' sx={{fontSize: '0.7rem !important', textAlign: 'start', color: 'white !important'}}>{`${i[0]}: ${i[1]}`}</Typography>)
  
     }
     
     
      
     <SubirNivelButton setError={setError} setMensaje={setMensaje} setOpen={setOpen} selector={selector}/>
           </Box>
           </Box>
    </Box>

    <Box class="rpgui-container framed-golden" sx={{position: 'relative !important',ml: 1,width:'50%', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Typography variant='p' sx={{display: 'block',fontSize: '1.5rem !important', textAlign: 'center !important'}}> Energy Factory </Typography>
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'start', width:'100%', height: '100%', flexDirection: 'column'}}>
      <Box sx={{width:'100%',display: 'flex', justifyContent: 'center',ml: 1}}>

        <CardMedia
                   className="rpgui-cursor-grab-open"
             component="img"
             image={agua}
             sx={{ width: "70px", objectFit: "cover" }}
           />
          </Box>
        <Typography variant='p'  sx={{fontSize: '1rem !important', textAlign: 'start', mt: 1}}> LVL: {selector?.nivel}</Typography>
        <Typography variant='p'  sx={{fontSize: '1rem !important', textAlign: 'centestartr', mt: 1}}>Yield: {selector?.maximo} Energy </Typography>
        <Typography variant='p'  sx={{fontSize: '1rem !important', textAlign: 'start', mt: 1}}>Energy: {selector?.produccion} x  {selector?.tiempoProduccion}hours</Typography>
        <Typography variant='p'  sx={{fontSize: '1rem !important', textAlign: 'start', mt: 1}}>To harvest you need: {selector?.costo} Carbon x Energy</Typography>
     <Typography variant='p'  sx={{fontSize: '1rem !important', textAlign: 'start', mt: 1}}>harvest: { 
 Math.floor(dayjs().utc().diff(selector?.tiempo, 'second') / (selector.tiempoProduccion * 3600))  <= selector?.maximo ? Math.floor(dayjs().utc().diff(selector?.tiempo, 'second') / (selector.tiempoProduccion * 3600)): selector?.maximo
       
       } </Typography>
 
 <Typography>
    Last claim: {selector?.tiempo}
    </Typography>
      
    <Typography sx={{ display: "flex"}}>
    Next energy:  <Contador segundos={ dayjs().utc().add((3600 * selector?.tiempoProduccion ) - dayjs().utc().diff(selector?.tiempo, 'second') % (3600 * selector?.tiempoProduccion ), "second").format()} />
    </Typography>
  

<Box sx={{width:'100%',display: 'flex', justifyContent: 'center',ml: 1}}>

         {selector?.tiempo === '' && <Button class="rpgui-button" onClick={activarAgua}  ><p> Turn on the energy factory</p></Button>}
         {   Math.floor(dayjs().utc().diff(selector?.tiempo, 'second') / (selector.tiempoProduccion * 3600))> 0 && <Button class="rpgui-button" onClick={recolectarSemilla}  ><p>harvest</p></Button>}
 
         {/* <Button class="rpgui-button"  ><p>collect</p></Button>
         <Button class="rpgui-button"  ><p>    plant seeds</p></Button> */}
        </Box>
        </Box>
       <Alerta mensaje={mensaje} open={open} handleClose={handleClose} error={error} />
    </Box>
    </Box>
    </Box>
  )
}
