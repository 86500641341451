import { Alert, Box, Button, CardMedia, Snackbar, Stack, Typography } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { rutas } from '../../rutas/rutas';
import { getCookie } from '../../utils/getCookies';



export const Kod = () => {
  
      const [open, setOpen] = useState(false);
      const [error, setError] = useState(false);
      const [mensaje, setMensaje] = useState("SUCCES");
      const configuracion = {
        idioma: 'jasjais'
      }
      const handleClose = (event, reason) => {
 
    
        setOpen(false);
      };
      const getAvatar = async()=>{
        const tokenJWT = getCookie('token')
     
        try {
          const {data} = await axios({
            method: 'get',
            url: rutas.ecosistema,
            headers:  {
              'token': tokenJWT,
              'Content-Type': 'application/json'}

          })
     
          if(data?.ok){
         
            setError(false)
            setOpen(true)
            setMensaje("SUCCES")
          }else{
            setError(true)
            setOpen(true)
          }
          
        } catch (error) {
          setError(true)
          setOpen(true)
          setMensaje(error?.response?.data?.error )
          console.log(error?.response?.data)
       //"Error you need the starter pack in King Of Duels or complete the daily mission in KOD, check and try again. "
        }
      }
  return (
    <Box sx={{width: '100%',height: '100%',  overflow: 'auto'}}>
        <Box sx={{width: '100%', height: '100%',display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'   ,  overflow: 'auto'}}>
           <CardMedia
    
    component="img"
    image={'https://www.kingofduels.online/static/media/LOGO_KOD_3.cd38a22e9acc8ce0ecdb.png'}
    alt="avatar"
    sx={{

      
      position: 'relative',
      zIndex: 0,
      width: { xs: "150px", sm: "200px" },
     
 
      
      backgroundPosition: 'center',
 
      backgroundSize: "contain",
      overflow: 'hidden'
    }}
  />
            
    <Typography variant='h4'>King Of Duels</Typography>
    <Typography sx={{fontSize: '1rem'}} >King of Duels is a free-to-play digital TCG (trading card game) focused on using creatures, spells and other means to reduce your opponent's total life to zero. Drawing inspiration from Yugioh and other similar games, King of Duels aims to be a unique playing experience that is still familiar to TCG vets.</Typography>
    <Typography sx={{fontSize: '1.3rem', fontStyle: 'italic'}} >If you have any initial pack in KOD and the general pack in cityfarm, you can randomly claim 1 water token or 1 energy token, with a 20% chance of getting both. </Typography>
    <a target="_blank" href='https://www.kingofduels.online/'>LINK</a>
    <Button sx={{m:1}} variant="contained" onClick={getAvatar}>Claim</Button>

   
        </Box>
        <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          {!error ? <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          SUCCESS
          </Alert>
          :
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        
            {mensaje
            }
          </Alert>
}
          
        </Snackbar>
      </Stack>
    </Box>
  )
}
