import { Box, Button, CardMedia, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import agua from '../../image/maiz.png'
import pasto from '../../image/pasto.png'
import { misionService } from '../../services/misionService'
import { setInformacion } from '../../store/slices/usuarios'
import { Alerta } from '../ux/Alert'
import dayjs  from 'dayjs'
import utc from 'dayjs/plugin/utc.js'
import { recolectarSevice } from '../../services/recolectarSevice'
import { tierras } from '../niveles/tierras'
import { Construccion } from './Construccion'
import { SubirNivelButton } from './botones/SubirNivelButton'
import { Contador } from '../comun/Contador'
dayjs.extend(utc)
export const TierraMaiz = ({selector, handleClose: handleClose2}) => {
  const [semillas, setSemillas] = useState(1)
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const {informacion  } = useSelector(state=> state.userSlice)
  const dispatch = useDispatch()

  const handleClose = ()=> setOpen(false)
  const handleChange = (e)=>{
    const cantidad = parseInt(e.target.value)
     if(cantidad > selector?.nivel)return
  
    setSemillas(cantidad)
  }
  const recolectarSemilla = async()=>{
    const { data } = await recolectarSevice(selector?.id)
    dispatch(setInformacion(data))
    setError(false)
    setMensaje('SUCCESS')
    setOpen(true)
  }
  const plantarSemilla = async()=>{
    try {
      const {data} = await misionService(selector?.id,semillas)
      console.log(data)
      dispatch(setInformacion(data))
      setError(false)
      setMensaje('SUCCESS')
      setOpen(true)
    } catch (error) {
      setError(true)
      setOpen(true)

      const msg = error?.response?.data?.error || "ERROR"
      setMensaje(msg)
  
    }
    
   
  }

  //   tierras.find(i=> i.id === selector.id).nivel[selector?.nivel - 1].forEach((item, index)=>{
  //     const clave = Object.entries(item)[index]
  //     console.log(clave)
  //   }
  // )
  return (

    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative !important', overflow: 'auto !important', width: '100%', height: '100%'}}>
   <Construccion selector={selector} />
      <Box onClick={handleClose2} sx={{position: 'abolute', right: 0, top: 0, zIndex: 10001, color: 'gray'}}>X</Box>
      <Box  class="rpgui-container framed-golden" sx={{position: 'relative !important'}}>
      <Typography variant='p'  sx={{color: 'white !important',fontSize: '0.7rem !important', textAlign: 'start', mt: 1}}>Inventory:  </Typography>
        <Typography variant='p'  sx={{color: 'white !important',fontSize: '0.7rem !important', textAlign: 'start', mt: 1}}>Corn: {informacion?.inventario.find(i=> i?.item === 'Corn')?.cantidad} </Typography>
        <Typography variant='p'  sx={{color: 'white !important',fontSize: '0.7rem !important', textAlign: 'start', mt: 1}}>Water: {informacion?.inventario.find(i=> i?.item === 'Water')?.cantidad} </Typography>
        <Typography variant='p'  sx={{color: 'white !important',fontSize: '0.7rem !important', textAlign: 'start', mt: 1}}>Corn seed: {informacion?.inventario.find(i=> i?.item === 'Corn seed')?.cantidad} </Typography>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%', flexDirection: {xs: 'column', md: 'row'}, overflow: 'auto !important', width: '100%', height: '100%'}}>

  
    <Box class="rpgui-container framed-golden-m" sx={{position: 'relative !important',width: '50%', display: 'flex',  alignItems: 'center',justifyContent: 'center', flexDirection: 'column',height: '70vh' }}>
    <CardMedia
                className="rpgui-cursor-grab-open"
               component="img"
               image={pasto}
               sx={{ width: "37%", objectFit: "cover", m: '0 auto'  }}
             />
               <Box sx={{width: '100%', height: '60%', display: 'flex',  alignItems: 'center',justifyContent: 'center' }}>
             <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
             <Typography variant='p' sx={{fontSize: '1rem !important',color: 'greenyellow !important'}}>+LVL UP <div style={{backgroundSize: '100%', width: '30px', height: '30px'}} class="rpgui-icon exclamation"></div> </Typography>
       {
        Object.entries(tierras.find(i=> i.id === selector.id).nivel[selector?.nivel - 1]).map(i=><Typography key={i[1]} variant='p' sx={{fontSize: '0.7rem !important', textAlign: 'start', color: 'white !important'}}>{`${i[0]}: ${i[1]}`}</Typography>)
    
       }
       
       
        
       <SubirNivelButton setError={setError} setMensaje={setMensaje} setOpen={setOpen} selector={selector}/>
             </Box>
             </Box>

    </Box>
    <Box class="rpgui-container framed-golden-m" sx={{position: 'relative !important',ml: 1,width:'50%', height: '70vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Typography variant='p' sx={{display: 'block',fontSize: '1.5rem !important', textAlign: 'center !important'}}>Corn Crop </Typography>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'start', width:'100%', height: '100%', flexDirection: 'column'}}>
        <Box sx={{width:'100%',display: 'flex', justifyContent: 'center',ml: 1}}>

        <CardMedia
                   className="rpgui-cursor-grab-open"
             component="img"
             image={"https://res.cloudinary.com/devuvyuij/image/upload/v1681670270/CITYFARMING/assets%20wallet/image_u7r7pw.png"}
             sx={{ width: "70px", objectFit: "cover" }}
           />
               </Box>
           
        <Typography variant='p' sx={{fontSize: '1rem !important', textAlign: 'start'}}>LVL: {selector?.nivel}</Typography>
        <Typography variant='p' sx={{fontSize: '1rem !important', textAlign: 'start'}}>Production: seed 1x2 Corn 24 hours</Typography>
        <Typography variant='p' sx={{fontSize: '1rem !important', textAlign: 'start'}}>Max seed: {selector?.nivel}</Typography>
        <Typography variant='p' sx={{fontSize: '1rem !important', textAlign: 'start'}}>Requirements: Water x {selector?.costo * parseInt(semillas)} seed</Typography>
       {selector?.tiempo !== '' && <Typography  variant='p' sx={{fontSize: '1rem !important', textAlign: 'center'}}>harvest:{ 
      selector?.semillas
       
       }</Typography>}
    { selector?.tiempo !== "" && <Contador segundos={ selector?.tiempo} />}


{ selector?.tiempo === '' &&   <select value={semillas} onChange={handleChange} class="rpgui-dropdown ">
	<option value="1">1 - seed</option>
	<option value="2">2 - seed</option>
	<option value="3">3 - seed</option>
	<option value="4">4 - seed</option>

</select>}

      
        <Box sx={{width:'100%',display: 'flex', justifyContent: 'center',ml: 1}}>

         {selector?.tiempo === '' && <Button class="rpgui-button" onClick={plantarSemilla}  ><p>plant seeds</p></Button>}
         { dayjs().utc().diff(selector?.tiempo, 'second') > 0 && <Button class="rpgui-button" onClick={recolectarSemilla}  ><p>harvest</p></Button>}
         {/* <Button class="rpgui-button"  ><p>collect</p></Button>
         <Button class="rpgui-button"  ><p>    plant seeds</p></Button> */}
        </Box>
        </Box>
       <Alerta mensaje={mensaje} open={open} handleClose={handleClose} error={error} />
    </Box>
    </Box>
    </Box>
  )
}
