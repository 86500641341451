import { Box, CardMedia, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import React from 'react'
import { useSelector } from 'react-redux'

export const Wallet = ({handleClose}) => {
    const {informacion  } = useSelector(state=> state.userSlice)

  return (
    <Box sx={{position: "relative", height: "100%", width:"100%"}}>
    <Box onClick={handleClose} sx={{position: 'abolute', right: 0, top: 0, zIndex: 10000000000001, color: 'gray'}}>X</Box>

<Grid2 sx={{mt:1, p:1}} container spacing={4}>

{informacion.inventario.map(i=>{
            return <Grid2  display={"flex"} justifyContent={"center"} alignItems={"center"} className="rpgui-container framed-golden" xs={6} md={4} key={i?.item}>
                <Typography>{i?.item} - {i?.cantidad}</Typography>
                <CardMedia
                   className="rpgui-cursor-grab-open"
             component="img"
             image={i?.url}
             sx={{ width: "20%", objectFit: "cover" }}
           />
            </Grid2>
        })}

</Grid2>
{/* <Box sx={{width: '100%', height: '90%', display: 'grid',     gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat( 1fr)', position: 'relative', overflow: 'auto'}}>
        {informacion.inventario.map(i=>{
            return <Box sx={{border: '1px solid gray', width: '230px', height: '100px'}} key={i?.item}>
                <Typography>{i?.item} - {i?.cantidad}</Typography>
                <CardMedia
                   className="rpgui-cursor-grab-open"
             component="img"
             image={i?.url}
             sx={{ width: "20%", objectFit: "cover" }}
           />
            </Box>
        })}

    </Box> */}
    </Box>

  )
}
