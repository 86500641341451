import { Box, Button, Modal, Paper, Typography } from '@mui/material'
import { packs } from '../niveles/packs'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { v4 as uuidv4 } from "uuid";
import axios from 'axios'
import { useEffect, useState } from 'react';
import { comprarServices } from '../../services/comprarServices';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from '../../utils/getCookies';
import { setInformacion } from '../../store/slices/usuarios';
import { rutas } from '../../rutas/rutas';
import { Alerta } from '../ux/Alert';
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

  
    boxShadow: 24,
    p: 0,
  };
export const PacksMarket = () => {
    const {informacion } = useSelector(state=> state.userSlice)
    const dispatch = useDispatch()

    const [ item, setItem ] = useState({})
    const [open3, setOpen3] = useState(false);
    const [ criptomoneda, setCriptomoneda ] = useState('KOD')
    const [ criptomonedaPrecio, setCriptomonedaPrecio ] = useState(0)
    const [hive, setHive ] = useState(0);
    const [cantidad, setCantidad ] = useState(1);
    const [error, setError] = useState(false)
    const [open, setOpen] = useState(false)
    const [mensaje, setMensaje] = useState('')
    const handleClose = ()=> setOpen(false)
    const handleClose3 = () => {
        setOpen3(false);
    }
 
    const getPrecio = async ()=>{
       const tokenPrice = await axios.post("https://engine.rishipanthee.com/contracts/", {
            jsonrpc: "2.0",
            id: 1688752539823,
            method: "find",
            params: {
              contract: "market",
              table: "metrics",
              query: { symbol: criptomoneda},
              limit: 1000,
              offset: 0,
            },
          });
          const precioCrip = tokenPrice?.data?.result[0]?.lastPrice
          const hive = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=hive&vs_currencies=usd");
          setHive(hive?.data?.hive?.usd)
          setCriptomonedaPrecio(precioCrip)
    }
    useEffect(()=>{
        getPrecio()
        if(criptomoneda === "HUESO" || criptomoneda === "KOD"){
          
            const totalHive =  (item?.precio/hive)
          
            const totalCripto = totalHive/criptomonedaPrecio
            setCantidad(totalCripto.toFixed(2))
        }
    },[criptomoneda,hive, criptomonedaPrecio])
    const comprar = async()=>{
        try {
           const data = await comprarServices(criptomoneda,item?.id)
           
           const tokenJWT = getCookie('token')
           if(tokenJWT){
       
       
       
             axios.get(`${rutas.getUsuario}`, { headers: {'token': tokenJWT}})
             .then(({data})=> {
           
               dispatch(setInformacion(data))
         
             })
           }
           handleClose3()
           setError(false)
           setMensaje('SUCCESS')
           setOpen(true)
        } catch (error) {
       
            setError(true)
            setOpen(true)
            handleClose3()
            const msg = error?.response?.data?.error || "ERROR"
            setMensaje(msg)
        
        }
    }
    const handleModal = (i)=>{
        setItem(i)
        if(i.id === 1 || i?.id > 4 &&  i?.id <= 6 ){
          if(i?.id ===5 )setCriptomoneda("HUESO")
          if(i?.id ===6 )setCriptomoneda("KOD")
       
           const totalHive =  (i?.precio/hive)
          

            const totalCripto = totalHive/criptomonedaPrecio
            setCantidad(totalCripto.toFixed(2))
        }else{
            const totalHive =  (i?.precio/hive)
            setCantidad(totalHive)
        }
        setItem(i)
        setOpen3(true)
    }
  return (
    <Box className="rpgui-container framed-golden" ><Grid container spacing={2}>
       
        {packs.map(i=>  <Grid   direction="column"  sx={{flexDirection: 'column',background: 'rgba(0,0,0,0.1)', m:1, display: 'flex', justifyContent: 'space-between'}}  key={uuidv4()} xs={12} md={4} lg={3}>

            <Box sx={{filter:   informacion?.pack[i.id] === false || informacion?.pack[i.id] === undefined ? "grayscale(0)" :  "grayscale(1)" }}>
            <p>{i?.nombre}</p>
            {i.inventario.map((e,i)=> <Typography  key={uuidv4()} >
                {Object.entries(e).map(a=> <Typography  key={uuidv4()} >{a[0]}: {a[1]}</Typography>)}
                
                </Typography>)}
           
                {i.tierras.map((e,i)=> <Typography  key={uuidv4()} >
            <Typography sx={{color: 'green !important'}}>{e.nombre}</Typography>
                
                </Typography>)}
           <Box>
           <Typography display="inline">{i?.precio} - $USD </Typography> <Typography  display="inline">{JSON.stringify(i?.monedas)}</Typography>

           </Box>
            </Box>
   
                <Button disabled={informacion?.pack[i.id] === true } onClick={()=>handleModal(i)} sx={{margin: '0 auto !important'}} class="rpgui-button " ><p >Buy</p></Button>
            </Grid> )
            
            
            }

        </Grid>
        <Modal
   open={open3}
   onClose={handleClose3}
   aria-labelledby="modal-modal-title"
   aria-describedby="modal-modal-description"
   sx={{ position: 'absolute'}}
 >
   <Box className="bordes rpgui-container"  sx={{...style, position: 'absolute'}}>
     <Box className="rpgui-container framed-golden" >
  { item?.id === 1 || item.id > 4 &&  item.id <= 6?     <Box sx={{minWidth: '300px', color: 'white'}}>
  {   item?.id === 1 &&   <select  style={{minWidth: '300px'}} value={criptomoneda} onChange={(e)=> setCriptomoneda(e.target.value)} class="rpgui-dropdown">
	<option value="KOD">KOD</option>
	<option value="HUESO">HUESO</option>

</select>}
<Typography>Price USD: {item?.precio} </Typography>
<Typography>Price Hive: {hive} </Typography>
            <Typography>Amount of {criptomoneda} required: {cantidad} </Typography>
        </Box>
        :
        <Box className="rpgui-container" sx={{minWidth: '300px', color: 'white'}}>
            <Typography>Price USD: {item?.precio} </Typography>
            <Typography>Price Hive: {hive} </Typography>
            <Typography>Amount of HIVE required: {cantidad} </Typography>

        </Box>
    }

<Button onClick={()=>comprar()} sx={{margin: '0 auto !important'}} class="rpgui-button " ><p >Buy</p></Button>

     </Box>
  
   </Box>

 
 </Modal>

 <Alerta mensaje={mensaje} open={open} handleClose={handleClose} error={error} />

        </Box>
  )
}
