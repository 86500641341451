import { Box, Button, CardMedia, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from "axios"
import granja from '../../image/Land_vacas.png'
import { setInformacion } from '../../store/slices/usuarios'
import { Alerta } from '../ux/Alert'
import dayjs  from 'dayjs'
import utc from 'dayjs/plugin/utc.js'
import { recolectarSevice } from '../../services/recolectarSevice'
import { rutas } from '../../rutas/rutas'
import { getCookie } from '../../utils/getCookies'
import { misionService } from '../../services/misionService'
import { tierras } from '../niveles/tierras'
import { Construccion } from './Construccion'
import { SubirNivelButton } from './botones/SubirNivelButton'
import { Contador } from '../comun/Contador'
import { v4 as uuid } from 'uuid'
dayjs.extend(utc)
const agua = 'https://res.cloudinary.com/devuvyuij/image/upload/v1680463588/CITYFARMING/vaca_plzywc.png'

export const Vaca = ({selector, handleClose: handleClose2}) => {
  const [semillas, setSemillas] = useState(1)
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const dispatch = useDispatch()
  const {informacion  } = useSelector(state=> state.userSlice)
  const handleClose = ()=> setOpen(false)
  const handleChange = (e)=>{
    const cantidad = parseInt(e.target.value)
    // if(cantidad > selector?.nivel)return
  
    setSemillas(cantidad)
  }
  const recolectarSemilla = async()=>{
try {
  const { data } = await recolectarSevice(selector?.id)
  dispatch(setInformacion(data))
  setError(false)
  setMensaje('SUCCESS')
  setOpen(true)
} catch ({response}) {
  setError(true)
  setOpen(true)
  setMensaje(response.data.error)
}
  }

  const alimentar = async()=>{
    try {
      const {data} = await misionService(selector?.id, 2)
     if(data?.error){
      setError(true)
      setOpen(true)
  
      setMensaje(data?.error)
      return
     }
      dispatch(setInformacion(data))
      setError(false)
      setMensaje('SUCCESS')
      setOpen(true)
    } catch ({response}) {
      setError(true)
      setOpen(true)
   
      setMensaje(response.data.error)
    }
    
   
  }
  const activarAgua = async()=>{
    try {
      const token = getCookie('token')
      const {data} = await axios({
        url: rutas.activarAgua,
        data: {id: 9},
        method: 'post',
        headers:{
            token: token
        }
    })
   
      dispatch(setInformacion(data))
      setError(false)
      setMensaje('SUCCESS')
      setOpen(true)
    } catch (error) {
      setError(true)
      setOpen(true)
      const msg = error?.response?.data?.error || "ERROR"
      setMensaje(msg)
    }
    
   
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative !important', overflow: 'auto !important', width: '100%', height: '100%'}}>
      <Construccion selector={selector} />
      <Box onClick={handleClose2} sx={{position: 'abolute', right: 0, top: 0, zIndex: 10001, color: 'gray'}}>X</Box>
      <Box  class="rpgui-container framed-golden" sx={{position: 'relative !important'}}>
      <Typography variant='p'  sx={{color: 'white !important',fontSize: '0.7rem !important', textAlign: 'start', mt: 1}}>Inventory:  </Typography>
        <Typography variant='p'  sx={{color: 'white !important',fontSize: '0.7rem !important', textAlign: 'start', mt: 1}}>Wheat bag: {informacion?.inventario.find(i=> i?.item === 'Wheat Bag')?.cantidad} </Typography>
        <Typography variant='p'  sx={{color: 'white !important',fontSize: '0.7rem !important', textAlign: 'start', mt: 1}}>Water: {informacion?.inventario.find(i=> i?.item === 'Water')?.cantidad} </Typography>
        <Typography variant='p'  sx={{color: 'white !important',fontSize: '0.7rem !important', textAlign: 'start', mt: 1}}>Milk: {informacion?.inventario.find(i=> i?.item === 'Milk')?.cantidad} </Typography>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%', flexDirection: {xs: 'column', md: 'row'}, overflow: 'auto !important', width: '100%', height: '100%'}}>

  
    <Box class="rpgui-container framed-golden" sx={{position: 'relative !important',width: '50%', display: 'flex',  alignItems: 'center',justifyContent: 'center', flexDirection: 'column',height: '100%', overflow: "auto" }}>
  <CardMedia
                   className="rpgui-cursor-grab-open"
             component="img"
             image={granja}
             sx={{ width: "65%", objectFit: "cover" , m: '0 auto'}}
           />
                      <Box sx={{width: '100%', height: '60%', display: 'flex',  alignItems: 'center',justifyContent: 'center' }}>
           <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
           <Typography variant='p' sx={{fontSize: '1rem !important',color: 'greenyellow !important'}}>+LVL UP <div style={{backgroundSize: '100%', width: '30px', height: '30px'}} class="rpgui-icon exclamation"></div> </Typography>
     {
      Object.entries(tierras.find(i=> i.id === selector.id).nivel[selector?.nivel - 1]).map(i=><Typography key={uuid()} variant='p' sx={{fontSize: '0.7rem !important', textAlign: 'start', color: 'white !important'}}>{`${i[0]}: ${i[1]}`}</Typography>)
  
     }
     
     
      
     <SubirNivelButton setError={setError} setMensaje={setMensaje} setOpen={setOpen} selector={selector}/>
           </Box>
           </Box>
  </Box>

  <Box class="rpgui-container framed-golden" sx={{position: 'relative !important',ml: 1,width:'50%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', overflow: "auto"}}>
      <Typography variant='p' sx={{display: 'block',fontSize: '1.5rem !important', textAlign: 'center !important'}}>  Cow Farm</Typography>
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'start', width:'100%', height: '100%', flexDirection: 'column'}}>
      <Box sx={{width:'100%',display: 'flex', justifyContent: 'center',ml: 1}}>
        <CardMedia
                   className="rpgui-cursor-grab-open"
             component="img"
             image={agua}
             sx={{ width: "90px", objectFit: "cover" }}
           />
  </Box>



        <Typography variant='p'  sx={{fontSize: '1rem !important', textAlign: 'start', mt: 1}}> LVL: {selector?.nivel}</Typography>
        <Typography variant='p'  sx={{fontSize: '1rem !important', textAlign: 'start', mt: 1}}>Production: 2 liters of milk x level </Typography>
        <Typography variant='p'  sx={{fontSize: '1rem !important', textAlign: 'start', mt: 1}}>Duration: 24 hours</Typography>
        <Typography variant='p'  sx={{fontSize: '1rem !important', textAlign: 'start', mt: 1}}>Requirements: 2 bag of wheat feed x level; 3 water x level</Typography>
       
        <Box sx={{width:'100%',display: 'flex', justifyContent: 'center',}}>
          
         {selector?.tiempo === '' && <Button onClick={alimentar} class="rpgui-button"  ><p> Feed</p></Button>}
         { dayjs().utc().diff(selector?.tiempo, 'second') > 0 && <Button class="rpgui-button" onClick={recolectarSemilla}  ><p>Collect</p></Button>}
         {/* <Button class="rpgui-button"  ><p>collect</p></Button>
         <Button class="rpgui-button"  ><p>    plant seeds</p></Button> */}
         
         </Box>
         { selector?.tiempo !== "" && <Contador segundos={ selector?.tiempo} />}
        </Box>
       <Alerta mensaje={mensaje} open={open} handleClose={handleClose} error={error} />
    </Box>
        
    </Box>
    </Box>
  )
}
