import axios from "axios"
import { rutas } from "../rutas/rutas"
import { getCookie } from "../utils/getCookies"

export const tokenHistoyry = async(item, token)=>{
   
        const tokenAUTH = getCookie('token')
         return axios({
            url: rutas.tokenHistory,
            data: {item, token},
            method: 'post',
            headers:{
                token: tokenAUTH
            }
        })

    
       
 
}
  