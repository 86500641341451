import { Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const secondsToString = (seconds) => {
   
  if(seconds <=0) return 'Completed'
  let hour = Math.floor(seconds / 3600);
  hour = (hour < 10) ? '0' + hour : hour;
  let minute = Math.floor((seconds / 60) % 60);
  minute = (minute < 10)? '0' + minute : minute;
  let second = seconds % 60;
  second = (second < 10)? '0' + second : second;
  return hour + ':' + minute + ':' + second;
}
export const Contador = ({segundos: seconds,}) => {
  const [ segundo, setSegundos ] = useState('0')
  useEffect(()=>{
    const tiempo = -1 * dayjs().utc().diff(seconds, 'second')

    let intervarlo = setInterval(()=>{
   
     
      setSegundos(secondsToString(tiempo)) 
    },1000)
    return ()=> clearInterval(intervarlo)
  },[segundo])
  return (
<>
<Typography style={{margin: 0, 'margin-left': '10px', fontSize:{xs:  '1rem', md:  '1.2rem'}}}> - Time: {segundo} </Typography>

</>
  )
}
