import axios from "axios"
import { rutas } from "../rutas/rutas"
import { getCookie } from "../utils/getCookies"
;

export const comprarToken = async(token,precio,criptomoneda,venta, cantidad, id)=>{


  
        const token2 = getCookie('token')
         return axios({
            url: rutas.comprarToken,
            data: {token,precio,criptomoneda,venta, cantidad, id},
            method: 'post',
            headers:{
                token: token2
            }
        })
    
       
 
}
  