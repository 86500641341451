export const URL_MAIN =process.env.NODE_ENV === 'production' ?  '' :  'http://localhost:3005' 
//'https://farm-production.up.railway.app'
//https://cityfarm.up.railway.app

//process.env.NODE_ENV === 'production' ? 'farm-production.up.railway.app' : 'http://localhost:3002'
//export const URL_SOCKET =  process.env.NODE_ENV === 'production' ? 'https://socketkod.com' : 'http://localhost:3003' 
// mejoras


//http://localhost:3002
//'https://kingofduels.azurewebsites.net'
//'https://socketkod.com'
export const rutas = {
    registarEmail: `${URL_MAIN}/api/registarEmail/`,
    mision: `${URL_MAIN}/api/mision/`,
    recolectar: `${URL_MAIN}/api/recolectar/`,
    activarAgua: `${URL_MAIN}/api/activarAgua/`,
    getUsuario: `${URL_MAIN}/api/getUsuario/`,
    edificaciones: `${URL_MAIN}/api/edificaciones/`,
    completarConstruccion: `${URL_MAIN}/api/completarConstruccion/`,
    venderToken: `${URL_MAIN}/api/venderToken/`,
    verToken: `${URL_MAIN}/api/verToken/`,
    comprarToken: `${URL_MAIN}/api/comprarToken/`,
    verMolino: `${URL_MAIN}/api/verMolino/`,
    procesarMolino: `${URL_MAIN}/api/procesarMolino/`,
    completarMolino: `${URL_MAIN}/api/completarMolino/`,
    comprar: `${URL_MAIN}/api/comprar/`,
    login: `${URL_MAIN}/api/login/`,
    asociar: `${URL_MAIN}/api/asociar/`,
    cambiarPass: `${URL_MAIN}/api/cambiarPass/`,
    depositStake: `${URL_MAIN}/api/depositStake/`,
    mirarStake: `${URL_MAIN}/api/mirarStake/`,
    retiroStake: `${URL_MAIN}/api/retiroStake/`,
    cancelarOrden: `${URL_MAIN}/api/cancelarOrden/`,
    ecosistema: `${URL_MAIN}/api/ecosistema/`,
    retiroReal: `${URL_MAIN}/api/retiroReal/`,
    rewardTorneo: `${URL_MAIN}/api/claimTorneo/`,
    getReferidos: `${URL_MAIN}/api/getReferidos/`,
    tokenHistory: `${URL_MAIN}/api/tokenHistory/`,
    getPrecioCF: `${URL_MAIN}/api/getPrecioCF/`,
    cambiarToken: `${URL_MAIN}/api/cambiarToken/`,
    usarObjeto: `${URL_MAIN}/api/usarObjeto/`,
    
    
    
    
}