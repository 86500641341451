import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import { useState } from 'react';
import { rutas } from '../../../rutas/rutas';
import { getCookie } from '../../../utils/getCookies';
import axios from "axios"
import { useSelector } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs: "80%", md: "50%"},

};

export default function Referido({open,handleClose}) {
  const {informacion  } = useSelector(state=> state.userSlice)

  const [ref, setref]= useState([])
  useEffect(()=>{
    const tokenAUTH = getCookie('token')

   axios.get(`${rutas.getReferidos}`, { headers: {'token': tokenAUTH}})
   .then(({data})=> {

    setref(data)
  
   })
  },[])
  console.log(ref)
  return (
    <div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="rpgui-container" 
      >
        <Box className="rpgui-container framed-golden"   sx={style}>
          <Box sx={{color: "white"}}><Typography sx={{color:"white"}}>For each referral who signs up with your link you will earn 15% of their investment in the packs sold in HIVE token. </Typography>
          Both links work, but if one doesn't open you can share the other.
          <Typography sx={{color:"greenyellow"}}>https://cityfarm.up.railway.app/?refer={informacion?.nombre}</Typography>  </Box>
          <Typography sx={{color:"greenyellow"}}>https://www.cityfarm.site/?refer={informacion?.nombre}</Typography> 
      {ref.map(i=><Box className="rpgui-container framed-grey" sx={{color: "white"}} key={i?._id}> id:{ i.nombreReferido} - General: {JSON.stringify(i?.compro["2"]) } - Special:  {JSON.stringify(i?.compro["3"]) } - Energy pack: {JSON.stringify(i?.compro["4"])  } - Water production: {JSON.stringify(i?.compro["7"])}    </Box>)}
        </Box>
      </Modal>
    </div>
  );
}
