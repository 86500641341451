import axios from "axios"
import { rutas } from "../rutas/rutas"
import { getCookie } from "../utils/getCookies"
;

export const comprarServices = async(criptomoneda, id)=>{


  
        const token2 = getCookie('token')
         return axios({
            url: rutas.comprar,
            data: {criptomoneda,id},
            method: 'post',
            headers:{
                token: token2
            }
        })
    
       
 
}
  