import { Box, Button, CardMedia, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import point from "../../logica/ui/img/cursor/point.png";
import { molinoServices } from "../../services/molinoServices";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import { Contador } from "../comun/Contador";
import { rutas } from "../../rutas/rutas";
import { getCookie } from "../../utils/getCookies";
import { setInformacion } from "../../store/slices/usuarios";
import axios from "axios";
import { Alerta } from "../ux/Alert";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { v4 as uuidv4 } from "uuid";
dayjs.extend(utc);
const activos = ["Corn", "Wood", "Wheat", "Milk", "Egg","Corn Bag",'Carbon', 'Wheat Bag',];
const activosMolino = [
  {
    item: "Corn",
    min: 1,
    max: 20,
    produce: "Corn Bag",
    ratio: 1,
    pares: true,
    tiempo: 72,
    costo: 1,
    cripto: false,
    urlProduce: "https://res.cloudinary.com/devuvyuij/image/upload/v1680727644/CITYFARMING/image_2_wzvgtv.png"
  },
  {
    item: "Wheat",
    min: 1,
    max: 20,
    produce: "Wheat Bag",
    ratio: 1,
    pares: false,
    tiempo: 72,
    costo: 1,
    cripto: false,
      urlProduce: "https://res.cloudinary.com/devuvyuij/image/upload/v1680727644/CITYFARMING/image_3_imgniw.png"
  },
  {
    item: "Wood",
    min: 1,
    max: 20,
    produce: "Carbon",
    ratio: 7,
    pares: false,
    tiempo: 72,
    costo: 1,
    cripto: false,
    urlProduce: "https://res.cloudinary.com/devuvyuij/image/upload/v1680463586/CITYFARMING/carbon_epy7k7.png"
  },
  {
    item: "Milk",
    min: 2,
    max: 20,
    produce: "CF",
    ratio: 50,
    pares: true,
    tiempo: 72,
    costo: 1,
    cripto: true,
    urlProduce: "https://res.cloudinary.com/devuvyuij/image/upload/v1680727644/CITYFARMING/image_1_e5q9zu.png"
  },
  {
    item: "Egg",
    min: 2,
    max: 20,
    produce: "CF",
    ratio: 50,
    pares: true,
    tiempo: 72,
    costo: 1,
    cripto: true,
    urlProduce: "https://res.cloudinary.com/devuvyuij/image/upload/v1680727644/CITYFARMING/image_1_e5q9zu.png"
  },
  {
    item: "Corn Bag",
    min: 2,
    max: 20,
    produce: "CF",
    ratio: 20,
    pares: true,
    tiempo: 72,
    costo: 1,
    cripto: true,
    urlProduce:"https://res.cloudinary.com/devuvyuij/image/upload/v1680727644/CITYFARMING/image_1_e5q9zu.png"
  },
  {
    item: "Wheat Bag",
    min: 2,
    max: 40,
    produce: "CF",
    ratio: 20,
    pares: true,
    tiempo: 72,
    costo: 1,
    cripto: true,
    urlProduce:"https://res.cloudinary.com/devuvyuij/image/upload/v1680727644/CITYFARMING/image_1_e5q9zu.png"
  },
  {
    item: "Carbon",
    min: 2,
    max: 40,
    produce: "CF",
    ratio: 1,
    pares: true,
    tiempo: 72,
    costo: 1,
    cripto: true,
    urlProduce:"https://res.cloudinary.com/devuvyuij/image/upload/v1680727644/CITYFARMING/image_1_e5q9zu.png"
  },
  {
    item: "CF",
    min: 80,
    max: 320,
    produce: "Ticket",
    ratio: 1/80,
    pares: false,
    tiempo: 1,
    costo: 1,
    cripto: false,
    ticket: true,
    urlProduce: "https://res.cloudinary.com/devuvyuij/image/upload/v1724168023/CITYFARMING/tickets/tikctes-cityfarm_qjujbv.gif"

  }
];
export const Molino = ({ handleClose4 }) => {
  const { informacion } = useSelector((state) => state.userSlice);
  const [selector, setSelector] = useState("CF");
  const [cantidad, setCantidad] = useState(0);
  const [monto, setMonto] = useState(0);
  const [pagina, setPagina] = useState(0);
  const [activosMolinoData, setActivos] = useState([]);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const handleCantidad = () => {
    const item = activosMolino.find((i) => i?.item === selector);

    if (selector === "" || cantidad > item?.max || cantidad < item?.min) {
      setCantidad(0);
      return;
    }

    const cantidadValida =
      cantidad % item?.min === 0 ? cantidad : cantidad - (cantidad % item?.min);

    setCantidad(cantidadValida);

    const montoConvertido = item?.ratio * cantidadValida;
    setMonto(montoConvertido);
  };

  const getMolinos = async () => {
    const data = await molinoServices();

    setActivos(data.data);
  };
  useEffect(() => {
    if (pagina === 1) {
      getMolinos();
    }
  }, [pagina]);

  //   useEffect(()=>{
  //     if(misionTiempo > 0){
  //         const intervarlo = setInterval(()=>{

  //           setTiempo(timeActual)

  //         },1000)

  //         return () => clearInterval(intervarlo)
  //     }
  // },[tiempo])

  const procesar = async () => {
    if (cantidad > 0) {
      try {
       const { data: {usuario} } = await molinoServices("post", { item: selector, cantidad: cantidad });
        setError(false);
        dispatch(setInformacion(usuario));
        setMensaje("SUCCESS");
        setOpen(true);
        getMolinos();
      } catch (error) {
        setError(true);
        setOpen(true);

        const msg = error?.response?.data?.error || "ERROR";
        setMensaje(msg);
      }
    }
  };

  const reclamar = async (id) => {
    try {
      await molinoServices("post", { id }, false);

      const tokenJWT = getCookie("token");
      if (tokenJWT) {
        axios
          .get(`${rutas.getUsuario}`, { headers: { token: tokenJWT } })
          .then(({ data }) => {
            getMolinos();
            dispatch(setInformacion(data));
          });
      }
      setError(false);
      setMensaje("SUCCESS");
      setOpen(true);
    } catch (error) {
      setError(true);
      setOpen(true);

      const msg = error?.response?.data?.error || "ERROR";
      setMensaje(msg);
    }
  };
const userValidos = ["cityfarm", "lavino"]
  return (
    <Grid2 container spacing={2} sx={{ height: "100%" }}>
      <Box
        onClick={handleClose4}
        sx={{
          position: "abolute",
          right: 0,
          top: 0,
          zIndex: 10000000000001,
          color: "gray",
        }}
      >
        X
      </Box>

      <Grid2
        xs={12}
        className="rpgui-container framed-golden"
        sx={{ width: "100%" }}
      >
        <Button onClick={() => setPagina(0)} className="rpgui-button">
          <p>Processor</p>
        </Button>
        <Button onClick={() => setPagina(1)} className="rpgui-button">
          <p>Materials in process</p>
        </Button>
      </Grid2>
      {pagina === 1 && (
        <Box
          sx={{
            height: "75%",
            width: "100%",
            position: "relative",
            display: "flex",
            overflow: "auto",
            flexDirection: "column",
          }}
          className="rpgui-container framed-golden-2"
        >
          {activosMolinoData.map((i) => (
            <Box
              key={uuidv4()}
              sx={{
                minWidth: "350px",
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
              className="rpgui-container framed-golden"
            >
              <Box sx={{ height: "100px", display: "flex" }}>
                <Typography
                  style={{ margin: 0, fontSize: { xs: "1rem", md: "1.2rem" } }}
                >
                  item: {i.item}
                </Typography>

                <Typography
                  style={{
                    margin: 0,
                    "margin-left": "10px",
                    fontSize: { xs: "1rem", md: "1.2rem" },
                  }}
                >
                  {" "}
                  - Ratio: {i.ratio}
                </Typography>
                <Typography
                  style={{
                    margin: 0,
                    "margin-left": "10px",
                    fontSize: { xs: "1rem", md: "1.2rem" },
                  }}
                >
                  {" "}
                  - Reward: {i.ganancia}
                </Typography>
              </Box>
              <Contador segundos={i?.tiempo} />
              {-1 * dayjs().utc().diff(i?.tiempo, "second") < 0 && (
                <Button
                  onClick={() => reclamar(i._id)}
                  className="rpgui-button"
                >
                  <p>Claim</p>{" "}
                </Button>
              )}
            </Box>
          ))}
        </Box>
      )}
      {pagina === 0 && (
        <Box
          sx={{
            height: "75%",
            width: "100%",
            position: "relative",
            display: "flex",
            overflow: "auto",
            flexDirection: { xs: "column", md: "row" },
          }}
          className="rpgui-container framed-golden-2"
        >
          <Grid2  container columns={{ xs: 1, md: 2, }} sx={{overflow: "auto", minHeight: 350, maxWidth: "350px"}} xs={10} md={3}>
           
        <Box
                  key={uuidv4()}
                  onClick={() => setSelector("CF")}
                  className={
                    selector === "CF"
                      ? "rpgui-container framed-golden"
                      : "rpgui-container framed-grey"
                  }
                  sx={{
                    cursor: `url(${point}) 10 0, auto`,
                    border: "1px solid gray",
                    width: "150px",
                    height: "80px",
                  }}
                >
                  <Typography>
                    CF - {informacion?.cf}
                  </Typography>
                  <CardMedia
                    className="rpgui-cursor-grab-open"
                    component="img"
                    image={"/favicon.ico"}
                    sx={{ width: "20%", objectFit: "cover" }}
                  />
                </Box>
            {activos.map((nombre) => {
              const i = informacion.inventario.find(
                (item) => item?.item === nombre
              );
              return (
                <Box
                  key={uuidv4()}
                  onClick={() => setSelector(nombre)}
                  className={
                    selector === nombre
                      ? "rpgui-container framed-golden"
                      : "rpgui-container framed-grey"
                  }
                  sx={{
                    cursor: `url(${point}) 10 0, auto`,
                    border: "1px solid gray",
                    width: "150px",
                    height: "80px",
                  }}
                >
                  <Typography>
                    {i?.item} - {i?.cantidad}
                  </Typography>
                  <CardMedia
                    className="rpgui-cursor-grab-open"
                    component="img"
                    image={i?.url}
                    sx={{ width: "20%", objectFit: "cover" }}
                  />
                </Box>
              );
            })}
          </Grid2>
          <Grid2
            xs={12}
            md={5}
            className="rpgui-container framed-grey"
            sx={{ ml: 2, minHeight: "300px" }}
          >
            <p>Quantity</p>
            <input
              value={cantidad}
              onBlur={handleCantidad}
              onChange={(e) =>
                e.target.value?.length > 1 && e.target.value[0] === "0"
                  ? setCantidad(e.target.value.slice(1, -1))
                  : setCantidad(e.target.value)
              }
              type="number"
              min="0"
              placeholder="Quantity"
            ></input>
            <p>Min: {activosMolino.find((i) => i?.item === selector)?.min} </p>
            <p>Max: {activosMolino.find((i) => i?.item === selector)?.max}</p>
            <p>
              Time: {activosMolino.find((i) => i?.item === selector)?.tiempo}H
            </p>
            <p>
              Ratio: {activosMolino.find((i) => i?.item === selector)?.ratio}
            </p>
            <p>
              Fee: {activosMolino.find((i) => i?.item === selector)?.costo}{" "}
              Energy
            </p>
          </Grid2>
          <Grid2
            xs={12}
            md={4}
            className="rpgui-container framed-grey"
            sx={{ ml: 2 }}
          >
            <p>
              Amount to be received: <span style={{color: "green"}}>{monto}</span> -{" "}
              {activosMolino.find((i) => i?.item === selector)?.produce}
            </p>

            <p>
              Time required:{" "}
              {activosMolino.find((i) => i?.item === selector)?.tiempo}hours
            </p>
              

            <CardMedia
                    className="rpgui-cursor-grab-open"
                    component="img"
                    image={activosMolino.find((i) => i?.item === selector)?.urlProduce}
                    sx={{ width: "35%", objectFit: "cover" }}
                  />
            <Button
              onClick={procesar}
              className="rpgui-button"
              sx={{ m: "0 auto" }}
            >
              <p>Start</p>
            </Button>
          </Grid2>
        </Box>
      )}
      {/* {secondsToString(   -1 * dayjs().utc().diff(i?.tiempo, 'second') )} */}
      <Alerta
        mensaje={mensaje}
        open={open}
        handleClose={handleClose}
        error={error}
      />
    </Grid2>
  );
};
