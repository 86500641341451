export const packs =   [
    {
        nombre: 'Initial',
        id: 1,
        monedas: ["KOD","HUESO"],
        inventario: [{"Water": 10,"Wheat seed": 10, "Energy": 2,"Wood": 2}],
        tierras: [{id: 3, nombre: "Wheat land"}],
        precio: 2.5
  
  
    },
    {
        nombre: 'General',
        id: 2,
        monedas: ["HIVE"],
        inventario: [{"Water": 20,"Corn seed": 10, "Energy": 4,"Wood": 4}],
        tierras: [{id: 1, nombre: "Land of Corn"}],
        precio: 5
  
    },
    {
        nombre: 'Special',
        id: 3,
        monedas: ["HIVE"],
        inventario: [{"Water": 35,"Wheat seed": 15,"Corn seed": 10, "Energy": 25, "Carbon": 10, "Wood": 5}],
        tierras: [{id: 2, nombre: "Land of Corn"}, {id: 4, nombre: "Wheat land"}, {id: 5, nombre: 'Land of Trees'}, {id: 10, nombre: 'Chicken coop'}],
        precio: 25
    },
    {
      nombre: 'Energy pack',
      id: 4,
      monedas: ["HIVE"],
      inventario: [{"Water": 35,"Wheat seed": 10,"Corn seed": 10, "Energy": 10, "Carbon": 15, "Wood": 10}],
      tierras: [{id: 7, nombre: 'Land of Trees'}, {id: 9, nombre: 'Power plant'} ],
      precio: 45
  }
  ,
  {
      nombre: 'Water production pack',
      id: 7,
      monedas: ["HIVE"],
      inventario: [{"Water": 15,"Wheat seed": 10,"Corn seed": 10, "Energy": 25, "Carbon": 15, "Wood": 10}],
      tierras: [ {id: 6, nombre: 'Land of Trees'}, {id: 8, nombre: 'Water well'} ,{id: 11, nombre: 'Cow farm'}],
      precio: 75
  },
    {
        nombre: 'Corn seed',
        id: 5,
        monedas: ["HUESO"],
        inventario: [{"Corn seed": 15}],
        tierras: [],
        precio: 0.15
    },
    {
        nombre: 'Wheat seed',
        id: 6,
        monedas: ["KOD"],
        inventario: [{"Wheat seed": 15}],
        tierras: [],
        precio: 0.15
    }
  ]