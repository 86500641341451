import { Box, Typography } from "@mui/material"
import { Kod } from "../ecosistema/Kod"

export const Ecosistema = ({handleClose}) => {
  return (
    <Box sx={{m:0}}>
              <Box onClick={handleClose} sx={{position: 'abolute', right: 0, top: 0, zIndex: 10001, color: 'gray'}}>X</Box>

              <Typography variant="h3">ECOSYSTEM</Typography>

              <Kod />

    </Box>
  )
}
