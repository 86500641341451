import { configureStore } from '@reduxjs/toolkit'
import userSlice from './slices/usuarios'


export default configureStore({
    reducer: {
     
        userSlice,
       
    }
    ,
    devTools:false
})