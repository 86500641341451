import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';
import { useState } from 'react';
import { rutas } from '../../../rutas/rutas';
import { getCookie } from '../../../utils/getCookies';
import axios from "axios"
import { useDispatch, useSelector } from 'react-redux';
import { getTokenCf } from '../../../services/getTokenCf';
import { setInformacion } from '../../../store/slices/usuarios';
import { Alerta } from '../../ux/Alert';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs: "80%", md: "50%"},
  height: "40%",

};

export default function CambioCf({open,handleClose}) {
  const {informacion  } = useSelector(state=> state.userSlice)
  const dispatch = useDispatch()

  const [cf, setCf]= useState({hueso: 1, kod:1})
  const [tipo, setTipo]= useState(false)
  const [cantidad, setCantidad]= useState(0)
  const [token, setToken]= useState("hueso")

  const [open2, setOpen] = useState(false)
  const [mensaje, setMensaje] = useState('')
  const [error, setError] = useState(false)
  const [confirmacion, setConfirmacion] = useState(false)

  const handleClose2 = ()=> setOpen(false)

  useEffect(()=>{
    if(!open){
      setTipo(false)
      setCantidad(0)
    }
  },[open])
  const cambiarCf= async(token )=>{

try {
  const tokenAUTH = getCookie('token')

  const {data} = await axios({
    url: rutas.cambiarToken,
    data: {token, tipo: tipo,cantidad},
    method: 'post',
    headers:{
        token: tokenAUTH,

    }
})
setError(false)
handleClose()
setMensaje('SUCCESS')
setOpen(true)
dispatch(setInformacion(data))
} catch ({response}) {
  setError(true)
  setOpen(true)
  setMensaje(response.data.error)
}
    
  }
  useEffect(()=>{
  getTokenCf().then(res=>{
    console.log(res.data)
    setCf(res?.data)
  }).catch(err=>console.log(err))



  },[])

  return (
    <div  className="rpgui-container" >

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="rpgui-container" 
      >
        <Box className="rpgui-container framed-golden"   sx={style}>
          {!confirmacion ? <Box sx={{color: "white"}}>
            Click
          <input style={{width: "30px"}} checked={tipo} onChange={(e)=>setTipo(e.target.checked)} class="rpgui-checkbox" type="checkbox"/><label style={{color:"white", fontSize: "1.1rem"}}>From cryptocurrency "KOD,HUESO" to CF, the change is a 10% difference..</label> 
          <hr className="golden"/>
            <Typography sx={{color:"white"}}>The CF to KOD or CF to HUESO conversion ratio is dynamic and may change over time. </Typography>
          <Typography sx={{color:"greenyellow"}}>{!tipo ? `Every ${cf.kod} CF is 1 $KOD` : `Every 1 $KOD is ${parseFloat(cf.kod * 0.9).toFixed(3)} CF`}</Typography>
          <Typography sx={{color:"greenyellow"}}>{!tipo ? `Every ${cf.hueso} CF is 1 $HUESO` : `Every 1 $HUESO is ${parseFloat(cf.hueso * 0.9).toFixed(3)} CF`}</Typography>
     
          <Typography sx={{color:"greenyellow"}}>CF token available: {informacion?.cf}</Typography> 
          <input value={cantidad} onChange={(e)=>e.target.value > 0 ? setCantidad(e.target.value) : setCantidad('') } style={{marginBottom: "5px"}} type="number" min={0}  placeholder="Quantity"></input>

          <Box sx={{ width: "100%",display: "flex", justifyContent: "center", mt:1}} className="rpgui-container">
            <button onClick={()=>{
              setConfirmacion(true)
              setToken("kod")}} style={{marginBottom: "5px", color:"white", fontWeight: 600}}  className="rpgui-button"> <p>CF - KOD</p></button>
           <button onClick={()=>{
            setConfirmacion(true)
            setToken("hueso")}}  style={{marginBottom: "5px", color:"white", fontWeight: 600}}  className="rpgui-button"> <p>CF - HUESO</p></button>


            </Box>
          </Box>
          :
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap:2}}>
            <Typography sx={{color: "white", fontWeight:600}}>Are you sure you can perform this conversion?
           </Typography>
           <Typography sx={{color: "white", fontWeight:600}}> Quantity of tokens to receive: {tipo ? `Token: ${"CF"}- Quantity: ${parseFloat(cf[token] * 0.9 * cantidad).toFixed(3)}` : `Token: ${token}- Quantity: ${parseFloat(cantidad/ cf[token]   ).toFixed(3)}` }</Typography>
<Box sx={{mt:2}}>

<button onClick={()=>{
            setConfirmacion(false)
            cambiarCf(token)}}  style={{marginBottom: "5px", color:"white", fontWeight: 600}}  className="rpgui-button"> <p>Accept</p></button>
<button onClick={()=>{
            setConfirmacion(false)
            }}  style={{marginBottom: "5px", color:"white", fontWeight: 600}}  className="rpgui-button"> <p>Cancell</p></button>
</Box>
          </Box>
          }


        </Box>

      </Modal>
      <Alerta mensaje={mensaje} open={open2} handleClose={handleClose2} error={error} />

    </div>
  );
}
