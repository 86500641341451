import { Alert, Snackbar } from "@mui/material"
import { Stack } from "@mui/system"

export const Alerta =({error , mensaje, open, handleClose})=>{
return (
    <Stack spacing={2} sx={{ width: "100%", zIndex: 100000000000 }}>
    <Snackbar sx={{ zIndex: 100000000 }} open={open} autoHideDuration={6000} onClose={handleClose}>
      {
        error ?
        <Alert
        onClose={handleClose}
        severity="error"
        sx={{ width: "100%" ,zIndex: 10000000000}}
        variant="filled"
      >
       {mensaje}
      </Alert>
      :
      <Alert
      onClose={handleClose}
      severity="success"
      sx={{ width: "100%" }}
      variant="filled"
    >
     {mensaje}
    </Alert>
      }
    </Snackbar>
  </Stack>
)

}