import axios from "axios"
import { getCookie } from "../utils/getCookies"
import { rutas } from "../rutas/rutas"

export const miraToken = async(token, criptomoneda)=>{
    console.log(`${rutas.verToken}${token}/${criptomoneda}` )
    const token2 = getCookie('token')
     return axios({
        url: `${rutas.verToken}${token}/${criptomoneda}`  ,
        method: 'get',
        headers:{
            token: token2
        },
     
    })

   

}
