
import { Inicio } from './logica/Inicio';
import './logica/ui/rpgui.css'
import './css/magic.css'
import './App.css';
import 'animate.css';


import { Provider } from 'react-redux';
import store from './store';
function App() {
  if (process.env.NODE_ENV === 'production') console.log = function () {};
  return (
    <Provider store={store} >
     {/* <CampoPrincipal /> */}
    <Inicio />
    </Provider >
  );
}

export default App;
