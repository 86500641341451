import { Box, Button, CardMedia , Typography} from "@mui/material";
import { claimTorneo } from "../../services/claimTorneo";
const torneos = [{fecha: "2023-10-17",horas: 0, id: "652be536f976d4ef917db0b8"},
{fecha: "2023-10-18",horas: 0, id: "652be53df976d4ef917db0bc"},
{fecha: "2023-10-19",horas: 0, id: "652be548f976d4ef917db0c0"},
{fecha: "2023-10-20",horas: 0, id: "652be54cf976d4ef917db0c4"},
{fecha: "2023-10-21",horas: 0, id: "652be550f976d4ef917db0c8"},
{fecha: "2023-10-22",horas: 0, id: "652be557f976d4ef917db0cc"},
]

export const Torneos = () => {
  const claim = async (id)=>{
    try {
      const res = await claimTorneo(id)
      alert("You claimed 2 water and 2  energy.")
    } catch (error) {
      console.log(error)
      alert(error?.response?.data?.error )
    }
  }
  return (
    <Box sx={{ textAlign: "center", overflow: "auto", width: "100%", height: "100%"}}>
             <CardMedia
                className="rpgui-cursor-grab-open"
                component="img"
                image={
                  "https://www.kingofduels.online/static/media/LOGO_KOD_2.453d3c68f872a140309a.png"
                }
                sx={{ width: 150, objectFit: "cover", m: "0 auto" }}
              />
              <Box>
                <p sx={{fontSize: "1.3rem"}} >Free tournaments sponsored by the "Big dog bone" game.
</p>
<p>Rules to participate:
</p>
<p>1) Free registration "no multi-accounts allowed".</p>
<p>2) each day you can claim 2 water and 2 energy for participating.</p>
<p>3) you need to play at least 3 games "Try not to get beaten to complete the 3 victory quickly I will be watching that, play fair and fun duels".</p>
<p>4) you need to have the general pack of 5 $ in city farm
The tournament is played every day at 00 UTC, each tournament lasts 1 hour and 30 minutes. </p>
<p>The tournaments will give prizes to the winners daily. 
</p>
<Box >
{torneos.map((i)=><Box sx={{justifyContent: "center", m:1}} display={"flex"}><p>Date: {i.fecha} - 00 UTC-</p><Button onClick={()=>claim(i.id)} variant="contained">Claim</Button></Box>)}
</Box>

              </Box>
    </Box>
  )
}
