import axios from "axios"
import { rutas } from "../rutas/rutas"
import { getCookie } from "../utils/getCookies"

export const depositStake = async(token, cantidad)=>{
   
        const tokenAUTH = getCookie('token')
         return axios({
            url: rutas.depositStake,
            data: {token, cantidad},
            method: 'post',
            headers:{
                token: tokenAUTH
            }
        })
    
       
 
}
  