import axios from "axios"
import { rutas } from "../rutas/rutas"
import { getCookie } from "../utils/getCookies"

export const cambiarPass = async(pass)=>{
   
        const token = getCookie('token')
         return axios({
            url: rutas.cambiarPass,
            data: {pass},
            method: 'post',
            headers:{
                token: token
            }
        })
    
       
 
}
  