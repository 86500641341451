import { Button } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { subirNivelServices } from '../../../services/subirNivel'
import { setInformacion } from '../../../store/slices/usuarios'

export const SubirNivelButton = ({selector, setMensaje,setError , setOpen}) => {
    const dispatch = useDispatch()
    const subirNivel = async()=>{
        try {
          const { data } = await subirNivelServices(selector?.id)

       
          dispatch(setInformacion(data))
          setError(false)
          setMensaje('SUCCESS')
          setOpen(true)
        } catch (error) {
          setError(true)
          setOpen(true)
          
          setMensaje(error?.response?.data?.error)
        }
      //edificaciones
        }
  return (
    <Button class="rpgui-button" onClick={subirNivel}  ><p>LVL UP</p></Button>
  )
}
