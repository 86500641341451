import axios from "axios"
import { rutas } from "../rutas/rutas"
import { getCookie } from "../utils/getCookies"

export const misionService = async(id,semilla)=>{
   
        const token = getCookie('token')
         return axios({
            url: rutas.mision,
            data: {id, semilla},
            method: 'post',
            headers:{
                token: token
            }
        })
    
       
 
}
  