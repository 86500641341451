
import axios from 'axios'
import { rutas } from '../rutas/rutas';
import { getCookie } from '../utils/getCookies';

export const signin = (username, handleLogin, parametros, asociar = false, referido) =>{
    try {
       // console.log(username, handleLogin, parametros, asociar )
        window.hive_keychain.requestHandshake(finishSignin(username, handleLogin, parametros, asociar, referido));
        //account-menu, login-box
    }
    catch (err) {
       alert("keychain not installed")
        //show modal with link to keychain website or "new to hive" info page
    }
}
export const transfer = (cantidad, memo= "Deposit HIVE", token = "SWAP.HIVE",user,setOpen3)=>{
     const json = {
         "contractName": "tokens",
         "contractAction": "transfer",
         "contractPayload": {
             "symbol": token,
             "to": "cityfarm",
             "quantity": cantidad.toString(),
             "memo": memo
 
         }
     }
 

     window.hive_keychain.requestCustomJson(user, "ssc-mainnet-hive", "Active", JSON.stringify(json), 'Transfer', function (response) {
        
     
         if (response.success && response.result) {
            alert("success")
     
            
       
         } else {
             console.log(response)
             alert("error")
             //show modal
         }
     });
 }
function finishSignin(username, handleLogin, parametros = "",asociar = false, referido) {
    console.log(asociar , rutas.login , rutas.asociar, username, asociar)
    const message = Date.now().toString()
    try {
        window.hive_keychain.requestSignBuffer(
            username,
            message,
            "Posting",
            function (response) {
                if (response.error) {
                    
                 alert("Error")
                } else {
                    let username = response.data.username
                
                    const token = getCookie('token') || ""
                 axios({
                       url: asociar === false ? rutas.login : rutas.asociar,
                       data: {nombre: username, key: response.result, message, parametros, referido},
                       method: 'post',
                       headers:{
                           token: token
                       }
                   }) 
                  
                    .then(res=> {
                        if(res.data?.ok){
                            alert('SUCCES')
                            return
                        }
                        const token = res.data?.token
                        document.cookie = `token=${token}; max-age=${3600 * 12}; path=/; samesite=strict; secure=true;`
                        handleLogin(res.data)
                      
                    })
                    .catch(err=> console.log(err))
                    //localStorage.setItem('username', username);
                  
               
                }
            }
        );
    }
    catch (e) {
        console.log(e)
    }
}
export function buy_nfts(nfts, price, priceSymbol, message) {
    let buy_multiple_nft = {
        "contractName": "nftmarket",
        "contractAction": "buy",
        "contractPayload": {
            "symbol": "NFTHUESO",
            "nfts": [nfts],
            "expPrice": price.toString(),
            "expPriceSymbol": priceSymbol.toString(),
            "marketAccount": "kingofduels"
        }
    }

    const username = JSON.parse(sessionStorage.getItem('user'))

        window.hive_keychain.requestCustomJson(username.user, "ssc-mainnet-hive", "Active", JSON.stringify(buy_multiple_nft), message, function (response) {
            console.log(response)
            if (response.success && response.result) {
                //show modal
            } else {
                //show modal
            }
        });
}
export function cancel_nft_sell(nfts) {
    let cancel_nft_listing = {
        "contractName": "nftmarket",
        "contractAction": "cancel",
        "contractPayload": {
            "symbol": "NFTHUESO",
            "nfts":[nfts]
        }
    }
    const username = JSON.parse(sessionStorage.getItem('user'))
  
    window.hive_keychain.requestCustomJson(username.user, "ssc-mainnet-hive", "Active", JSON.stringify(cancel_nft_listing), 'Cancel NFT Sell Listing?', function (response) {
        if (response.success && response.result) {
            //show modal
        } else {
            //show modal
        }
    });
}

export function sell_nft(nft, price, priceSymbol, message) {


    let buy_nft = {
        "contractName": "nftmarket",
        "contractAction": "sell",
        "contractPayload": {
            "symbol": "NFTHUESO",
            "nfts": [nft.toString()],
            "price": price.toString(),
            "priceSymbol": priceSymbol.toString(),
            "marketAccount": "mv-deposito",
            "fee": 500
        }
    }

    const username = JSON.parse(sessionStorage.getItem('user'))

        window.hive_keychain.requestCustomJson(username.user, "ssc-mainnet-hive", "Active", JSON.stringify(buy_nft), "Vender NFT", function (response) {
           console.log(response)
            if (response.success && response.result) {
                //show modal
            } else {
                //show modal
            }
        });

}